import React from "react";
import { Link } from "react-router-dom";

import Arrow from "../assets/Arrow.svg";

const scrollToTop = () => {
	window.scrollTo({ top: 0, behavior: "smooth" });
};

const Projects = ({ image, title, description, url, mode, projects }) => {
  return (
    <div className={`projects ${mode} md:w-[340px] lg:w-[532px] rounded-xl my-5`}>
      <div
        className={`projects-image ${mode} w-full h-[250px] lg:h-[384px] rounded overflow-hidden relative`}
      >
        <img
          src={image}
          alt={title}
          className="w-full h-full object-cover transition-opacity duration-500"
        />
      </div>

      <div className="mx-6 md:mx-4 lg:mx-14 mb-6 md:mb-4 lg:mb-14 mt-4 md:mt-8 lg:mt-12">
        <h2 className="text-2xl mb-4 md:text-3xl md:mb-4 lg:text-[36px]">
          {title}
        </h2>
        <span className="text-[16px]">{description}</span>
      </div>

      <Link to={url} state={{ data: projects }} onClick={scrollToTop}>
        <div className="flex justify-between items-center mx-6 md:mx-4 lg:mx-14 mb-6 md:mb-4 lg:mb-14">
          <div
            className={`py-2 sm:py-4 pri-teal ${mode} rounded-[8px] cursor-pointer text-[white] text-[16px] font-bold`}
          >
            View Project
          </div>
          <div
            className={`flex items-center justify-between projects-link ${mode}`}
          >
            <img src={Arrow} alt="arrow" />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Projects;
