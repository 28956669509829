import React, { useState, useEffect } from "react";
import Main from "../containers/Main";
import Banner from "../components/Banner";
import { urlFor, client } from "../client";
import FeaturedProjects from "../components/FeaturedProjects";
import Projects from "../components/Projects";
import Pagination from "../components/Pagination";
import { motion, useIsPresent } from "framer-motion";

const Portfolio = () => {
  const [projects, setProjects] = useState([]);
  const [totalNo, setTotal] = useState(0);
  const [index, setIndex] = useState([0, 4]);

  useEffect(() => {
    const query = '*[_type == "portfolio"] | order(date desc)';

    client.fetch(query).then((data) => {
      console.log(data);
      setProjects(data);
    });
  }, []);

  const latestProject = projects[0];

  const App = (mode) => (
    <div>
      <motion.div
        initial={{ opacity: 0, y: "100%" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "100%" }}
        transition={{ duration: 0.5 }}
      >
        <Banner title="Our Portfolio" mode={mode} />
        <div className="m-5 md:mx-16 md:mt-10 md:mb-20 lg:mt-20 lg:mx-44">
          <div className="bg-[#C5FFFA] rounded-xl">
            <FeaturedProjects
              title="Featured Project"
              image={latestProject ? urlFor(latestProject.featuredImage) : ""}
              name={latestProject ? latestProject.title : ""}
              description={latestProject ? latestProject.overview : ""}
              url={latestProject ? `/project/${latestProject._id}` : ""}
              projects={projects}
              mode={mode}
            />
          </div>
          <div className="my-5">
            <h2
              className={`font-bold text-3xl md:text-4xl lg:text-5xl mb-5 md:mb-6 lg:mb10 mt-20 all-projects ${mode}`}
              mode={mode}
            >
              All Projects
            </h2>
            <div className="flex flex-col md:flex-row md:flex-wrap gap-5 justify-start">
              {projects.slice(index[0], index[4]).map((project, index) => (
                <Projects
                  image={urlFor(project.featuredImage)}
                  title={project.title}
                  description={project.overview}
                  mode={mode}
                  key={index}
                  project={project}
                  projects={projects}
                  url={`/project/${project._id}`}
                />
              ))}
            </div>
          </div>
          <Pagination
            data={projects}
            totalNo={totalNo}
            mode={mode}
            onClick={(prop) => setIndex(prop)}
          />
        </div>
      </motion.div>
    </div>
  );
  return (
    <div>
      <Main App={App} showSearch={true} data={projects} />
    </div>
  );
};

export default Portfolio;
