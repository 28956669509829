/** @format */

import React, { useEffect, useState } from "react";
import BlogCard from "./BlogCard";
import { urlFor, client } from "../client";

const formatDate = (dateString) => {
	const options = { year: "numeric", month: "long", day: "numeric" };
	return new Date(dateString).toLocaleDateString(undefined, options);
};

const BlogLanding = ({ mode }) => {
	const [blogLanding, setBlogLanding] = useState([]);

	useEffect(() => {
		const query = '*[_type == "blogPost"] | order(date desc)';

		client.fetch(query).then((data) => {
			setBlogLanding(data);
		});
	}, []);

	return (
		<div
			className={`px-5 md:px-10 lg:px-44 py-10 lg:pb-40 w-full text-center items-center ${
				mode === "dark" ? "bg-[bg-[#4F4F4F]" : "bg-[#F5F5F5]"
			}`}
		>
			<h1
				className={`pri-teal ${mode} text-[36px] md:text-[61px] lg:text-center mb-10 text-center lg:text-[80px] font-bold`}
			>
				Our Blog
			</h1>

			<div className="w-full my-4 flex flex-wrap 2xl:justify-center">
				{blogLanding.slice(0, 4).map((blog, index) => (
					<div
						className="m-4 flex flex-wrap"
						key={index}
					>
						<BlogCard
							image={urlFor(blog.coverImage).url()}
							date={`0 Comments | ${formatDate(blog.publishedAt)}`}
							title={blog.title}
							desc={blog.overview}
							mode={mode}
						/>
					</div>
				))}
			</div>
		</div>
	);
};
export default BlogLanding;
