/** @format */

import React, { useEffect, useState } from "react";
import Main from "../containers/Main";
import Banner from "../components/Banner";
import { NIG_FAQ_DATA, US_FAQ_DATA } from "../data/data";
import { motion, useIsPresent } from "framer-motion";


const FAQ = () => {
  const location = localStorage.getItem("loc");
  const [presentFAQ, setpresentFAQ] = useState(
    location === "ng" ? NIG_FAQ_DATA[0].data : US_FAQ_DATA[0].data
  );
  const [answerFAQ, setAnswerFAQ] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [active, setActive] = useState(false);

  const clickAction = (prop1, prop2) => {
    setSelectedIndex(prop2);
    setpresentFAQ((location === "ng" ? NIG_FAQ_DATA : US_FAQ_DATA)[prop2].data);
  };

  const showAnswer = (prop1, prop2) => {
    setAnswerFAQ(prop1);
    selectedAnswerIndex === null
      ? setSelectedAnswerIndex(prop2)
      : setSelectedAnswerIndex(null);
    setActive(!active);
  };

  // const deviceWidth = window.screen.availWidth;

  const App = (mode) => (
    <div className="w-full ">
      <motion.div
        initial={{ opacity: 0, y: "100%" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "100%" }}
        transition={{ duration: 0.5 }}
      >
        <Banner title="Frequently Asked Questions" mode={mode} />

        <div style={{ color: mode === "light" ? "black" : "white" }}>
          <div className="w-full flex items-center px-24 py-10">
            <div className=" mx-auto py-10 w-2/3 md:w-1/5">
              {(location === "ng" ? NIG_FAQ_DATA : US_FAQ_DATA).map(
                (item, index) => (
                  <p
                    key={index}
                    className="my-6 cursor-pointer text-xs md:text-lg"
                    style={{
                      fontWeight: index === selectedIndex ? "bold" : "normal",
                    }}
                    onClick={() => clickAction(item, index)}
                  >
                    {item.Topic}
                  </p>
                )
              )}
            </div>
            <div className="hidden md:block md:w-3/5">
              <div>
                {presentFAQ.map((item, index) => (
                  <div
                    key={index}
                    style={{ borderBottom: "1px solid #E3E7EA" }}
                  >
                    <div
                      className="flex align-middle cursor-pointer items-center"
                      onClick={() => showAnswer(item.answer, index)}
                    >
                      <span
                        className="text-3xl m-6 cursor-pointer "
                        onClick={() => showAnswer(item.answer, index)}
                      >
                        {selectedAnswerIndex === index ? "-" : "+"}
                      </span>

                      <p key={index} className="my-6 font-semibold">
                        {item.question}
                      </p>
                    </div>
                    {selectedAnswerIndex === index ? (
                      <p className="ml-20">{answerFAQ}</p>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="md:hidden">
            {/* <div className="flex justify-between text-xs">
            <p style={{color:"#D80739"}} className=" font-bold">Bids and Purchases</p>
          <p style={{color:"#0D101B59"}}  className="font-semibold">Vehicles and Conditions</p>
          </div> */}
            <div className=" ">
              <div>
                {presentFAQ.map((item, index) => (
                  <div key={index}>
                    <div
                      className="flex align-middle"
                      style={{ borderBottom: "1px solid #E3E7EA" }}
                    >
                      <img
                        src={
                          selectedAnswerIndex === index
                            ? "./assets/img/faqNegative.svg"
                            : "./assets/img/faq.svg"
                        }
                        className="w-3 h-3 my-6 mr-6 cursor-pointer "
                        onClick={() => showAnswer(item.answer, index)}
                      />
                      <p
                        key={index}
                        className="py-6 font-semibold text-xs w-full"
                      >
                        {item.question}
                      </p>
                    </div>
                    {selectedAnswerIndex === index ? (
                      <p className="ml-10 text-xs">{answerFAQ}</p>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* <div className="w-2/3 mx-auto text-center my-10">
          {location === "ng" ? (
            ""
          ) : (
            <p>
              These FAQs should be regularly reviewed and updated to reflect the
              latest services, policies, and regulations, especially considering
              the dynamic nature of tech industries in the U.S.
            </p>
          )}
        </div> */}
        </div>
      </motion.div>
    </div>
  );

  return <Main App={App} />;
};

export default FAQ;
