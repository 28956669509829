/** @format */

import { LOADING, USER_LOCATION, SWITCH_MODE} from "../types";


// const API = process.env.REACT_APP_API_KEY;

export const GetUserLocation = async (dispatch, long, lat) => {
  // try {
  //   const res = await fetch(
  //     `https://api.weatherapi.com/v1/forecast.json?key=4425a651fe15441dbdd220355231910&q=${lat},${long}&days=1&aqi=no&alerts=no`,
  //     {
  //       method: "POST",
  //     }
  //   );

  //   const res_json = await res.json();
  //   console.log(res_json);
  //   if (!res.ok) {
  //     console.log("something is wrong, could not get location");
  //   } else {
  //     dispatch({
  //       type: USER_LOCATION,
  //       payload: res_json.location.country,
  //     });
  //   }
  // } catch (error) {
  //   console.log(error);
  // }
};

export const SwitchMode = async (dispatch, mode, location) => {
  dispatch({
    type: SWITCH_MODE,
    payload: mode,
    payload1: location,
  });
};
