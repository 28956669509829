import React from 'react';
import PrivacyInfo from './PrivacyInfo';
import { PrivacyText, PrivacyList } from '../data/data';

const PrivacyInfoContainer = ({ mode }) => {
  return (
    <div className="mb-[120px] md:mb-[152px] lg:mb-[214px]">
      <PrivacyInfo
        title="1. Information We Collect"
        text={PrivacyText[0].section1}
        mode={mode}
      />
      <PrivacyInfo
        title="2. How We Use Your Information"
        text={PrivacyText[0].section2}
        list={PrivacyList[0].list2}
        mode={mode}
      />
      <PrivacyInfo
        title="3. Data Protection"
        text={PrivacyText[0].section3}
        mode={mode}
      />
      <PrivacyInfo
        title="4. Cookies"
        text={PrivacyText[0].section4}
        mode={mode}
      />
      <PrivacyInfo
        title="5. Third Party Websites"
        text={PrivacyText[0].section5}
        mode={mode}
      />
      <PrivacyInfo
        title="6. Children's Privacy"
        text={PrivacyText[0].section6}
        mode={mode}
      />
      <PrivacyInfo
        title="7. Your Rights"
        text={PrivacyText[0].section7}
        list={PrivacyList[0].list7}
        mode={mode}
      />
      <PrivacyInfo
        title="8. Changes to this Privacy Policy"
        text={PrivacyText[0].section8}
        mode={mode}
      />
      <PrivacyInfo
        title="9. Contact Us"
        text={PrivacyText[0].section9}
        mode={mode}
      />
    </div>
  );
};

export default PrivacyInfoContainer;
