import {
  LOADING,
  SWITCH_MODE,
  USER_LOCATION
  
} from "../types";

export const initialState = {
  user: {},
  loading: false,
  mode: "light",
  location: "",
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SWITCH_MODE:
      return {
        ...initialState,
        mode: action.payload,
        location: action.payload1,
      };
    case USER_LOCATION:
      return {
        ...initialState,
        location: action.payload,
      };
    default:
      return state;
  }
};

export default user;
