/** @format */

import React from "react";
import Title from "./Title";
import MissionCard from "./MissionCard";
import VisionIcon from "../assets/VisionIcon.svg";
import MissionIcon from "../assets/MissionIcon.svg";
import ValuesIcon from "../assets/ValuesIcon.svg";

const WhoWeAre = ({ mode, location }) => {
	return (
		<div className="px-5 md:px-10 lg:px-36 relative">
			<Title
				mode={mode}
				text={"Who We Are"}
			/>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="6"
				height="148"
				viewBox="0 0 6 148"
				fill="none"
				className="hidden lg:flex absolute -top-20 right-36"
			>
				<path
					d="M2.85645 142.333C1.38369 142.333 0.189785 143.527 0.189785 145C0.189785 146.473 1.38369 147.667 2.85645 147.667C4.32921 147.667 5.52312 146.473 5.52312 145C5.52312 143.527 4.32921 142.333 2.85645 142.333ZM2.35645 2.18557e-08L2.35645 145L3.35645 145L3.35645 -2.18557e-08L2.35645 2.18557e-08Z"
					fill={mode === "dark" ? "#15ECE2" : "#D1D1D1"}
				/>
			</svg>
			<div className="flex flex-col gap-5 mb-20 lg:flex-row md:mb-28 lg:mb-72 mt-10">
				<MissionCard
					icon={MissionIcon}
					title="Our Mission"
					text={`We empower ${
						location === "us" ? "" : "Nigerian "
					} businesses with cutting-edge technology solutions`}
					mode={mode}
				/>

				<MissionCard
					icon={VisionIcon}
					title="Our Vision"
					text={`Leading innovation in ${
						location === "us" ? "" : "Nigerian's "
					} tech landscape`}
					mode={mode}
				/>
				<MissionCard
					icon={ValuesIcon}
					title="Our Values"
					text="Innovation, Adaptability, Integrity, Expertise, and Commitment"
					mode={mode}
				/>
			</div>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="2"
				height="145"
				viewBox="0 0 2 145"
				fill="none"
				className=" hidden lg:flex absolute  -bottom-60 right-36"
			>
				<path
					d="M0.856445 0L0.856452 145"
					stroke={mode === "dark" ? "#15ECE2" : "#D1D1D1"}
				/>
			</svg>
		</div>
	);
};

export default WhoWeAre;
