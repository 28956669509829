/** @format */

import React, { useState } from "react";
import Number from "./Number";

const Pagination = ({ data, totalNo, mode, onClick }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  const totalPages = Math.ceil(totalNo / itemsPerPage);
  console.log(data);
  const onPageChange = (pageNo) => {
    setCurrentPage(pageNo);
    const startIndex = (pageNo - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    onClick([startIndex, endIndex]);
  };
  return (
    <div className="flex items-center">
      {[...Array(totalPages).keys()]?.map((page, index) => (
        <div className="mx-2" onClick={() => onPageChange(index + 1)}>
          <Number text={index + 1} mode={mode}  active={currentPage===index+1?true:false}/>
        </div>
      ))}
    </div>
  );
};

export default Pagination;
