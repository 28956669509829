import React from 'react';
import Management from './Management';
import Hus from "../assets/husse.webp";
import Toks from "../assets/toks.webp";

const AboutUsCards = ({ title, gallery, mode }) => {
  return (
    <div className="w-full mb-20 md:mb-32 lg:mb-56">
      <h2
        className={`about-headers ${mode} font-bold text-[27px] md:text-4xl mb-5`}
        mode={mode}
      >
        {title}
      </h2>
      <div className="w-full  mx-auto overflow-x-auto flex justify-center gap-5 md:flex-wrap lg:flex">
        <Management
          image={Toks}
          name="Olatokunbo Cole"
          position="Founder"
          mode={mode}
        />
        <Management
          image={Hus}
          name="Ahmmed 0. Hussein"
          position="C.T.O"
          mode={mode}
        />
      </div>
    </div>
  );
};

export default AboutUsCards;
