// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/TTNorms-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/TTNorms-Bold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/fonts.css */

/* Example for a single font file */
@font-face {
    font-family: 'TT Norms';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
    /* Add more src lines for different file formats if needed */
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'TT Norms B';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('opentype');
    /* Add more src lines for different file formats if needed */
    font-weight: normal;
    font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB,mCAAmC;AACnC;IACI,uBAAuB;IACvB,+DAA2D;IAC3D,4DAA4D;IAC5D,mBAAmB;IACnB,kBAAkB;AACtB;;;AAGA;IACI,yBAAyB;IACzB,+DAAwD;IACxD,4DAA4D;IAC5D,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":["/* src/styles/fonts.css */\n\n/* Example for a single font file */\n@font-face {\n    font-family: 'TT Norms';\n    src: url('../fonts/TTNorms-Regular.otf') format('opentype');\n    /* Add more src lines for different file formats if needed */\n    font-weight: normal;\n    font-style: normal;\n}\n\n\n@font-face {\n    font-family: 'TT Norms B';\n    src: url('../fonts/TTNorms-Bold.otf') format('opentype');\n    /* Add more src lines for different file formats if needed */\n    font-weight: normal;\n    font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
