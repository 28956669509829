import React, { useState, useEffect } from "react";
import { urlFor, client } from "../client";
import Main from "../containers/Main";
import Banner from "../components/Banner";
import BlogCard from "../components/BlogCard";
import BlogCardFeatured from "../components/BlogCardFeatured";
import Pagination from "../components/Pagination";
import Pill from "../components/Pill";
import { motion, useIsPresent } from "framer-motion";


const formatDate = (dateString) => {
	const options = { year: "numeric", month: "long", day: "numeric" };
	return new Date(dateString).toLocaleDateString(undefined, options);
};

const Blog = () => {
	const [blogs, setBlogs] = useState([]);
	const [allBlogs, setAllBlogs] = useState([]);
	const [totalNo, setTotal] = useState(0);
	const [index, setIndex] = useState([0, 4]);
	const [selectedTag, setSelectedTag] = useState("all");
	const [mobileFilter, setMobileFilter] = useState(false);
	const [featuredBlog, setFeaturedBlog] = useState(0);
	const latestBlog = blogs.length > 0 ? blogs[featuredBlog] : null;

	const CareerTags = [
		{
			text: "View all",
			tag: "all",
		},
		{
			text: "Cloud",
			tag: "cloud",
		},
		{
			text: "Development",
			tag: "development",
		},
		{
			text: "Design",
			tag: "design",
		},
		{
			text: "Documentation",
			tag: "documentation",
		},
	];

	useEffect(() => {
		const query = '*[_type == "blogPost"]{..., tags} | order(date desc)';
		client.fetch(query).then((data) => {
			const blogs = data.map((item) => {
				const tags = Array.isArray(item.tags)
					? item.tags.map((tag) => tag)
					: [];
				return {
					...item,
					tags: tags,
				};
			});

			setBlogs(blogs);
			setAllBlogs(blogs);
			setTotal(blogs.length);
		});
	}, []);

	const handleBlogFilter = (tag) => {
		setSelectedTag(tag);

		if (tag === "all") {
			setBlogs(allBlogs);
		} else {
			const filteredBlogs = allBlogs.filter((blog) => blog.tags.includes(tag));
			setBlogs(filteredBlogs);
		}

		// Close the mobile filter dropdown after selecting a tag
		setMobileFilter(false);
	};

	const App = (mode) => (
    <div className=" w-full items-center ">
      <motion.div
        initial={{ opacity: 0, y: "100%" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "100%" }}
        transition={{ duration: 0.5}}
      >
        <Banner title="Blog" mode={mode} />
        <div className="m-5 md:mx-16 md:mt-10 md:mb-20 lg:mt-20 lg:mx-24">
          <div className="py-10">
            <h1 className="font-bold text-xl md:text-[28px] mb-5 md:mb-6 text-[#008080]">
              Featured
            </h1>
            {latestBlog && (
              <BlogCardFeatured
                image={urlFor(latestBlog.coverImage).url()}
                date={`0 Comments | ${formatDate(latestBlog.publishedAt)}`}
                title={latestBlog.title}
                desc={latestBlog.overview}
                url={latestBlog.links.url}
                mode={mode}
              />
            )}
            <div className="flex items-center  mt-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                className="cursor-pointer"
                onClick={() =>
                  setFeaturedBlog(featuredBlog > 0 ? featuredBlog - 1 : 0)
                }
              ></svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                className="mx-5 cursor-pointer"
                onClick={() =>
                  setFeaturedBlog(
                    featuredBlog >= blogs.length - 1
                      ? blogs.length - 1
                      : featuredBlog + 1
                  )
                }
              ></svg>
            </div>

            <hr className="my-5" />
          </div>

          <div className="hidden md:flex items-center w-full lg:w-4/5 my-3 mb-10 md:mb-12 overflow-x-scroll">
            {CareerTags.map((item, index) => (
              <div onClick={() => handleBlogFilter(item.tag)} key={index}>
                <Pill
                  active={selectedTag === item.tag}
                  title={item.text}
                  tag={item.tag}
                />
              </div>
            ))}
          </div>

          <div className="md:hidden items-center w-full my-4 mb-10 md:mb-12 relative">
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                onClick={() => setMobileFilter(!mobileFilter)}
              >
                <path
                  d="M10.9404 22.65C10.4604 22.65 9.99039 22.53 9.55039 22.29C8.67039 21.8 8.14039 20.91 8.14039 19.91V14.61C8.14039 14.11 7.81039 13.36 7.50039 12.98L3.76039 9.02001C3.13039 8.39001 2.65039 7.31001 2.65039 6.50001V4.20001C2.65039 2.60001 3.86039 1.35001 5.40039 1.35001H18.6004C20.1204 1.35001 21.3504 2.58001 21.3504 4.10001V6.30001C21.3504 7.35001 20.7204 8.54001 20.1304 9.13001L15.8004 12.96C15.3804 13.31 15.0504 14.08 15.0504 14.7V19C15.0504 19.89 14.4904 20.92 13.7904 21.34L12.4104 22.23C11.9604 22.51 11.4504 22.65 10.9404 22.65ZM5.40039 2.85001C4.70039 2.85001 4.15039 3.44001 4.15039 4.20001V6.50001C4.15039 6.87001 4.45039 7.59001 4.83039 7.97001L8.64039 11.98C9.15039 12.61 9.65039 13.66 9.65039 14.6V19.9C9.65039 20.55 10.1004 20.87 10.2904 20.97C10.7104 21.2 11.2204 21.2 11.6104 20.96L13.0004 20.07C13.2804 19.9 13.5604 19.36 13.5604 19V14.7C13.5604 13.63 14.0804 12.45 14.8304 11.82L19.1104 8.03001C19.4504 7.69001 19.8604 6.88001 19.8604 6.29001V4.10001C19.8604 3.41001 19.3004 2.85001 18.6104 2.85001H5.40039Z"
                  fill="#008080"
                />
                <path
                  d="M5.99968 10.75C5.85968 10.75 5.72968 10.71 5.59968 10.64C5.24968 10.42 5.13968 9.94999 5.35968 9.59999L10.2897 1.69999C10.5097 1.34999 10.9697 1.23999 11.3197 1.45999C11.6697 1.67999 11.7797 2.13999 11.5597 2.48999L6.62968 10.39C6.48968 10.62 6.24968 10.75 5.99968 10.75Z"
                  fill="#008080"
                />
              </svg>
              <p className="text-[#008080] text-[16px] font-bold ml-2">
                Filter
              </p>
            </div>
            {mobileFilter && (
              <div
                className="z-20 h-100 w-100 absolute top-10 bg-black rounded-md py-5"
                style={{ background: "#F6F6F6" }}
              >
                {CareerTags.map((item, index) => (
                  <div key={index} onClick={() => handleBlogFilter(item.tag)}>
                    <Pill
                      active={selectedTag === item.tag}
                      title={item.text}
                      tag={item.tag}
                    />
                  </div>
                ))}
                {/* Add a 'View All' button in the mobile filter dropdown */}
                <div onClick={() => handleBlogFilter("all")}>
                  <Pill
                    active={selectedTag === "all"}
                    title="View all"
                    tag="all"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="w-full flex flex-wrap">
            {blogs.slice(index[0], index[1]).map((blog, index) => (
              <div className="m-4" key={index}>
                <BlogCard
                  image={urlFor(blog.coverImage).url()}
                  date={`0 Comments | ${formatDate(blog.publishedAt)}`}
                  title={blog.title}
                  desc={blog.overview}
                  url={blog.links.url}
                  mode={mode}
                />
              </div>
            ))}
          </div>
          <Pagination
            data={blogs}
            totalNo={totalNo}
            mode={mode}
            onClick={(prop) => setIndex(prop)}
          />
        </div>
      </motion.div>
    </div>
  );

	return (
		<div>
			<Main
				App={App}
				showSearch={true}
				data={blogs}
			/>
		</div>
	);
};

export default Blog;
