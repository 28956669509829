import React from 'react';

const Number = ({ text, mode, active }) => {
  const activeStyle = {
    background: "#C5FFFA",
    color: "#008080",
    border: "1px solid #C5FFFA",
  };

  const inactiveStyle = {
    background: "#F5F5F5",
    color: "#D1D1D1",
    border: "1px solid #F5F5F5",
  };
  return (
    <div
      className={`flex justify-center items-center rounded-full h-10 w-10 cursor-pointer font-bold number ${mode} rounded-full`}
      style={active ? activeStyle : inactiveStyle}
    >
      {text}
    </div>
  );
};

export default Number;
