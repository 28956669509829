/** @format */

import React from "react";
import GAWS from "../assets/aboutLogoGrid.svg";
import DGAWS from "../assets/darkservices.svg"

const Services = ({ mode }) => {
  return (
    <div
      className={`w-full  lg:h-[200px]`}
      style={{ objectFit: "cover" }}
    >
      <img
        src={mode === "dark" ? DGAWS : GAWS}
        alt=""
        className="w-full h-full "
        style={{ objectFit: "cover" }}
      />
    </div>
  );
};

export default Services;