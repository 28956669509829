/** @format */

import React, { useEffect } from "react";
import Main from "../containers/Main";
import Hero from "../components/Hero";
import WhoWeAre from "../components/WhoWeAre";
import WhatWeDo from "../components/WhatWeDo";
import LandingPageProject from "../components/LandingPageProject";
import Services from "../components/Services";
import BlogLanding from "../components/BlogLanding";
import { motion, useIsPresent } from "framer-motion";

const LandingPage = () => {
  useEffect(() => localStorage.setItem("loc", "ng"), []);
  const App = (mode) => (
    <motion.div
      initial={{ opacity: 0, x: "-100%" }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: "100%" }}
      transition={{ duration: 1 }}
    >
      <div className="w-full">
        <Hero mode={mode} location="ng" />
        <WhoWeAre mode={mode} location="ng" />
        <WhatWeDo mode={mode} location="ng" />
        <LandingPageProject mode={mode} />
        <div className="my-20">
          <Services mode={mode} />
        </div>
        <div>
          <BlogLanding mode={mode} />
        </div>
      </div>
    </motion.div>
  );
  return (
    <div>
      <Main App={App} location="ng" />
    </div>
  );
};
export default LandingPage;
