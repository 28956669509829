/** @format */

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Main from "../containers/Main";
import { useParams, useNavigate } from "react-router";
import { urlFor, client } from "../client";
import Banner from "../components/Banner";
import { motion, useIsPresent } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CareersForm = () => {
  const App = (mode) => {
    const [form, setForm] = useState({});
    const [loadText, setLoadText] = useState("Apply");
    const [selectedFile, setSelectedFile] = useState(null);

    const { id } = useParams();
    const { title, description } = form;
    const navigate = useNavigate();
    const hiddenFileInput = useRef(null);

    const [careerDetails, setCareerDetails] = useState({
      email: "",
      name: "",
      resume: "",
      coverLetter: "",
      jobTitle: "",
    });

    const CheckInputs = () => {
      if (
        careerDetails.email === "" ||
        careerDetails.name === "" ||
        careerDetails.resume === ""
      ) {
        return false;
      } else {
        return true;
      }
    };
    const handleSubmit = async (e) => {
      console.log(careerDetails);
      e.preventDefault();

      if (!CheckInputs()) {
        return toast.error(`Please Fill the Empty Fields `, {
          position: "top-right",
          autoClose: 5000,
          theme: mode === "light" ? "light" : "dark",
        });
      }

      setLoadText("...");
      // Use Formspree's endpoint as the form action
      const formspreeEndpoint = "https://formspree.io/f/xjvqqynp";
      try {
        const formData = new FormData();
        for (const key in careerDetails) {
          formData.append(key, careerDetails[key]);
        }
        console.log(formData);
        // Make a POST request to Formspree
        const response = await fetch(formspreeEndpoint, {
          method: "POST",
          body: formData,
        });

        console.log(response);

        if (!response.ok) {
          throw new Error(`Failed to submit application: ${response.status}`);
        }

        // Optionally, you can handle success, reset the form, or show a success message
        toast.success("Application submitted successfully!", {
          position: "top-right",
          autoClose: 5000,
          theme: mode === "light" ? "light" : "dark",
        });
        setCareerDetails({
          email: " ",
          name: " ",
          resume: " ",
          coverLetter: " ",
          jobTitle: "",
        });
        setLoadText("Apply");
        navigate("/success");
      } catch (error) {
        console.log(error.stack);
        // if (response.ok) {
        //   setLoadText("Apply");
        //   navigate("/success");
        // }
        // Handle errors, such as network issues or server errors
        //   toast.error(`Error submitting application: ${error}`, {
        //     position: "top-right",
        //     autoClose: 5000,
        //     theme: mode === "light" ? "light" : "dark",
        //   });
        // }
        console.log(error);
        navigate("/success");
      }
    };

    const handleFileChange = (event) => {
      setSelectedFile(event?.target?.files[0]);
      setCareerDetails((prevState) => ({
        ...prevState,
        resume: event?.target?.files[0],
      }));
    };
    const handleClick = (event) => {
      hiddenFileInput.current.click();
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await client.fetch(
            `*[_type == "careers" && _id == "${id}"]{ title, description }`
          );

          const careerData = response[0];

          if (careerData) {
            console.log(careerData);
            setForm(careerData);
            setCareerDetails((prevState) => ({
              ...prevState,
              jobTitle: careerData.title,
            }));
          } else {
            console.error(`No career data found for ID: ${id}`);
          }
        } catch (error) {
          console.error("Error fetching career data:", error);
        }
      };

      fetchData();
    }, [id]);

    if (!title || !description) {
      return null;
    }
    return (
      <div>
        <motion.div
          initial={{ opacity: 0, y: "100%" }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: "100%" }}
          transition={{ duration: 0.5 }}
        >
          <Banner title={title} mode={mode} />
          <div className="w-full flex flex-wrap items-center ">
            <div
              className={`flex items-center mx-20 cursor-pointer form-header ${mode}`}
            >
              <Link to="/careers" className="flex items-center">
                <h1 className="text-[30px] mr-2"> &#x2190;</h1>
                <p>Back to Careers Page</p>
              </Link>
            </div>
            <div className="w-full py-5 lg:py-0 px-5 md:px-20 lg:px-60 lg:mx-40 mx-auto ">
              <h1
                className={`form-header ${mode} text-[21px] md:text-[61px] text-cente font-bold mb-7 `}
              >
                Apply Here
              </h1>
              <p className={`text-base md:text-xl form-header ${mode}`}>
                {description}
              </p>
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <input
                  placeholder="Full Name"
                  className={`form-input ${mode} outline-none bg-[white] rounded-[12px] py-4 px-4 mt-5 w-full text-[#6D6D6D]`}
                  style={{
                    border: `1px solid ${
                      mode === "light" ? "#E7E7E7" : "#15ECE2"
                    }`,
                    background: mode === "light" ? "#FFF" : "#333",
                  }}
                  onChange={(event) =>
                    setCareerDetails((prevState) => ({
                      ...prevState,
                      name: event.target.value,
                    }))
                  }
                />
                <input
                  placeholder="Email Address"
                  className="outline-none rounded-[12px] py-4 px-4 mt-5 w-full text-[#6D6D6D]"
                  style={{
                    border: `1px solid ${
                      mode === "light" ? "#E7E7E7" : "#15ECE2"
                    }`,
                    background: mode === "light" ? "#FFF" : "#333",
                  }}
                  onChange={(event) =>
                    setCareerDetails((prevState) => ({
                      ...prevState,
                      email: event.target.value,
                    }))
                  }
                />
                <div className="mt-5">
                  <label
                    htmlFor="file-input"
                    className="text-sm text-[#6D6D6D]"
                  >
                    {selectedFile ? selectedFile.name : "Choose a file"}
                  </label>
                  <input
                    placeholder="Link to Resume"
                    className="outline-none rounded-[12px] py-4 px-4 mt-5 w-full text-[#6D6D6D]"
                    style={{
                      border: `1px solid ${
                        mode === "light" ? "#E7E7E7" : "#15ECE2"
                      }`,
                      background: mode === "light" ? "#FFF" : "#333",
                      // display: "none",
                    }}
                    name="upload"
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                  />
                  {/* <div
                    className={
                      mode === "dark"
                        ? "text-center lg:w-1/4 rounded-[12px] p-3 text-[#333] bg-[#15ECE2] cursor-pointer font-bold"
                        : "text-center lg:w-1/4 rounded-[12px] p-3 text-[#fff] bg-[#333] cursor-pointer font-bold"
                    }
                    style={{
                      border: `1px solid ${
                        mode === "light" ? "#E7E7E7" : "#15ECE2"
                      }`,
                    }}
                    onChange={(event) =>
                      setCareerDetails((prevState) => ({
                        ...prevState,
                        resumeLink: event.target.files[0],
                      }))
                    }
                    onClick={handleClick}
                  >
                    Upload Resume
                  </div> */}
                </div>
                <textarea
                  placeholder="Cover Letter (Optional)"
                  className="outline-none bg-[white] rounded-[12px] h-[244px] resize-none py-4 px-4 mt-5 w-full text-[#6D6D6D]"
                  style={{
                    border: `1px solid ${
                      mode === "light" ? "#E7E7E7" : "#15ECE2"
                    }`,
                    background: mode === "light" ? "#FFF" : "#333",
                  }}
                  onChange={(event) =>
                    setCareerDetails((prevState) => ({
                      ...prevState,
                      coverLetter: event.target.value,
                    }))
                  }
                ></textarea>

                <button
                  className="flex w-full lg:w-1/3 my-5 text-center items-center justify-center font-semibold text-[16px]  px-auto text-center mx-auto items-center py-3 sm:py-4 px-3 sm:px-5 rounded-[8px] cursor-pointer "
                  style={{
                    color: `${mode === "light" ? "#FFFFFF" : "#333333"}`,
                    background: `${mode === "light" ? "#008080" : "#15ECE2"}`,
                  }}
                  type="submit"
                >
                  {loadText}
                </button>
              </form>
            </div>
          </div>
        </motion.div>
        <ToastContainer />
      </div>
    );
  };

  return (
    <div>
      <Main App={App} />
    </div>
  );
};

export default CareersForm;
