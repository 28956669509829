import React,{useState} from 'react'
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link } from 'react-router-dom';

const Cookiess = ({mode}) => {
    const [cookiesVisible, setCookiesVisible]= useState(false)
  return (
    <div className="mt-38">
      <CookieConsent
        location="bottom"
        buttonText="Allow cookies"
        cookieName="myAwesomeCookieName2"
        style={{
            background: "rgba(51, 51, 51, 0.50)",
            backdropFilter:"blur(12px)",
          padding: "5px 40px",
        }}
        buttonStyle={{
          color: "#FFF",
          background: "#004080",
          fontSize: "16px",
          padding: "15px",
          fontWeight: "700",
            borderRadius: "12px",
          width:"100%"
        }}
        expires={150}
        debug={false}
      >
        <div className={`ml-[20px] pri-teal ${mode} `}>
          We use cookies on our website to optimize your site experience. By
          navigating the site,
          <br /> you agree to our use of cookies. Read our{" "}
          <Link to="/privacy" className='font-bold text-underline'>Privacy Policy</Link> to learn more.
        </div>
      </CookieConsent>
    </div>
  );
}

export default Cookiess
