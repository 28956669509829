import React from "react";
import { Link } from "react-router-dom";

import Arrow from "../assets/Arrow.svg";

const scrollToTop = () => {
	window.scrollTo({ top: 0, behavior: "smooth" });
};

const FeaturedProjects = ({ title, image, name, description, url, mode,projects }) => {
	return (
    <div className={`featured-projects ${mode} p-6 md:p-10 lg:p-14 rounded-xl`}>
      <h2 className="font-bold text-[#008080] mb-4 md:mb-6 lg:mb-10 md:text-2xl lg:text-5xl">
        {title}
      </h2>
      <div className="w-full h-auto rounded-xl mb-4 md:mb-6">
        <img src={image} alt={name} className="w-full h-auto" />
      </div>
      <div className="mb-6">
        <h2 className="text-xl mb-2 md:text-3xl md:mb-4 lg:text-5xl">{name}</h2>
        <span className="text-xs md:text-base">{description}</span>
      </div>
      <Link to={url} state={{ data: projects }} onClick={scrollToTop}>
        <div className="flex items-center justify-between">
          <div className="font-bold lg:text-xl text-[#008080]">
            View Project
          </div>
          <img src={Arrow} alt="arrow" />
        </div>
      </Link>
    </div>
  );
};

export default FeaturedProjects;
