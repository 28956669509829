import React from 'react';

const PrivacyInfo = ({ title, text, list, mode }) => {
  return (
    <div className={`privacy-color ${mode} mb-6 lg:mb-10`} mode={mode}>
      <h2 className="mb-2 lg:mb-[6px] font-bold md:text-xl">{title}</h2>
      <p className="md:text-xl">{text}</p>
      {list && list.length > 0 && (
        <ul>
          {list.map((item, index) => (
            <li key={index} className="md:text-xl">
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PrivacyInfo;
