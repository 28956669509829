/** @format */

import React from "react";
import background from "../assets/hero-background.png";
import ServerRoom from "../assets/server-room.gif";
// import Cookies from "./Cookies";
import { Link } from "react-router-dom";

const Hero = ({ mode, location }) => {
  console.log(mode);
  return (
    <div
      className={`h-full lg:h-[960px] relative px-5 lg:px-36 pt-16 overflow-hidden`}
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundColor: `${mode === "dark" ? "#262626" : "#FFFFFF"}`,
      }}
    >
      <div className=" w-full lg:w-2/3">
        <h1
          className={`pri-teal ${mode} text-[36px] md:text-[61px]  lg:text-[79px] font-bold`}
          style={{ letterSpacingSpacing: "-3.2px" }}
        >
          Cutting-edge <br className="" />
          technology solutions
          <br /> for businesses
        </h1>
        <p className={`basic ${mode} my-5 text-[16px] md:text-[21px]`}>
          We empower {location === "us" ? "" : "Nigerian "}businesses with
          cutting-edge technology solutions
        </p>

        <div className="flex items-center my-14 ">
          <Link to={location === "ng" ? "/about" : "/aboutus"}>
            <div className="flex items-center py-3 sm:py-4 px-3 sm:px-5 bg-[#008080] rounded-[8px] cursor-pointer text-[white] font-bold ">
              <p className=" text-[16px]">Learn More</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className="sm:ml-2"
              >
                <path
                  d="M14.4297 5.93018L20.4997 12.0002L14.4297 18.0702"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.5 12H20.33"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </Link>{" "}
          <Link to={location === "ng" ? "/contacts" : "/contactus"}>
            <div
              className={`text-center  mx-10   py-3 sm:py-4 px-3 sm:px-5  pri-teal ${mode} rounded-[8px] cursor-pointer text-[white] text-[16px] font-bold `}
              style={{
                border: `1px solid
               ${mode === "dark" ? "#15ECE2" : "#008080"}`,
              }}
            >
              Contact Us
            </div>
          </Link>
        </div>
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="174"
        viewBox="0 0 6 174"
        fill="none"
        className=" hidden lg:flex"
      >
        <path
          d="M3.00001 174L5.88676 169L0.113256 169L3.00001 174ZM2.5 2.18557e-08L2.50001 169.5L3.50001 169.5L3.5 -2.18557e-08L2.5 2.18557e-08Z"
          fill={mode === "dark" ? "#15ECE2" : "#D1D1D1"}
        />
      </svg>

      <div>
        <img
          src={ServerRoom}
          alt=""
          className="lg:absolute lg:-right-32 lg:top-[185px] lg:w-3/5"
        />
      </div>
    </div>
  );
};
export default Hero;
