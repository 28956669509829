import React from "react";
import ProjectButton from "./ProjectButton";
import { Link } from "react-router-dom";

const BlogCardFeatured = ({ image, title, text, desc, date, url, mode }) => {
	return (
		<div className="lg:flex lg:space-x-4  items-center">
			<div className="rounded-sm mb-2 md:mb-4 lg:w-1/2 h-full">
				<div
					className={`blog-card-tag ${mode} p-2 rounded-full border border-black max-w-max mb-2 md:hidden`}
				>
					<p className="text-uppercase text-[14px]">
						Digital Transformation, Machine Learning
					</p>
				</div>
				<div className="relative md:block ">
					<img
						src={image}
						alt={title}
						className="w-full h-full"
					/>
					<div
						className={`blog-card-tag ${mode} absolute top-4 left-4 p-2 rounded-full border border-black max-w-max  hidden md:block`}
					>
						<p className="text-uppercase text-[14px]">
							Digital Transformation, Machine Learning
						</p>
					</div>
				</div>
			</div>

			<div className="lg:w-1/2 h-full relative">
				<div className="flex items-center mb-2 ">
					<div className="w-full mx-3  md:mb-0   ">
						<p
							className={` text-[#6D6D6D] py-1 lg:py-3 text-[8px] md:text-[12px] lg:text-[16px]  lg:-top-52 lg:absolute  `}
						>
							{date}
						</p>
						<div className="lg:absolute lg:-bottom-32">
							<h1
								className={` md:hidden blog-card-header-text ${mode} font-bold  text-[14px] lg:text-[26px] xl-[36px]`}
							>
								{title.slice(0, 30)}
								{title.length > 30 ? "..." : ""}
							</h1>
							<h1
								className={`hidden md:flex blog-card-header-text ${mode} font-bold  text-[20px] lg:text-[26px] xl-[36px]`}
							>
								{title.slice(0, 90)}
								{title.length > 90 ? "..." : ""}
							</h1>
							<p
								className={`py-2  md:hidden blog-card-header-text ${mode}  lg:py-4 text-[10px] md:text-[14px] lg:text-[16px]`}
							>
								{desc.slice(0, 150)}
								{desc.length > 150 ? "..." : ""}
							</p>

							<p
								className={`py-2 hidden md:flex  blog-card-header-text ${mode} lg:py-4 text-[10px] md:text-[14px] lg:text-[14px] xl-[16px]`}
							>
								{desc.slice(0, 250)}
								{desc.length > 250 ? "..." : ""}
							</p>

							<div className="">
								<Link to={url} target="_blank">
									<ProjectButton
										text="Continue Reading"
										fullWidth
										mode={mode}
									/>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlogCardFeatured;
