// ProjectButton.js
import React from "react";

const ProjectButton = ({
	fullWidth,
	text,
	icon,
	projects,
	currentIndex,
	mode,
}) => {
	return (
		<button
			className={`project-button ${mode} font-bold text-xs py-2 lg:py-4 rounded-xl flex items-center justify-center ${
				fullWidth ? "w-full" : "px-6"
			}`}
		>
			{text}
			<span className="ml-3">
				{icon && (
					<img
						src={icon}
						alt={text}
						className={`arrow-icon ${mode}`}
					/>
				)}
			</span>
		</button>
	);
};

export default ProjectButton;
