import React from 'react';

const MissionCard = ({ icon, title, text, mode }) => {
  return (
    // Having trouble centering it in medium/Tablet mode/size
    <div className={`mission-card ${mode} shadow-md flex flex-col bg-black p-6 gap-4 text-white rounded-xl md:flex-row md:items-center lg:gap-2 lg:flex-col lg:items-start w-full`}>
      <div className="bg-white rounded-lg p-3 w-16">
        <img src={icon} alt={title} />
      </div>
      <div className="flex flex-col">
        <h3 className="font-semibold text-lg mb-2">{title}</h3>
        <span className="font-light text-base max-w-[300px]">{text}</span>
      </div>
    </div>
  );
};

export default MissionCard;
