// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --background-color-light: #ffffff;
    --text-color-light: #333333;



    --background-color-dark: #262626;
    --text-color-dark: #ffffff;
    --navheader-background-color-dark: #26262633;
    --navheader-text-color-dark: #26262633;
    --navheader-background-button-color-dark: #15ECE2;
}

`, "",{"version":3,"sources":["webpack://./src/styles/Shared.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,2BAA2B;;;;IAO3B,gCAAgC;IAChC,0BAA0B;IAC1B,4CAA4C;IAC5C,sCAAsC;IACtC,iDAAiD;AACrD","sourcesContent":[":root {\n    --background-color-light: #ffffff;\n    --text-color-light: #333333;\n    --navheader-background-color-dark: #26262633;\n    --navheader-text-color-dark: #26262633;\n    --navheader-background-button-color-dark: #15ECE2;\n\n\n\n    --background-color-dark: #262626;\n    --text-color-dark: #ffffff;\n    --navheader-background-color-dark: #26262633;\n    --navheader-text-color-dark: #26262633;\n    --navheader-background-button-color-dark: #15ECE2;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
