import React from "react";
import Main from "../containers/Main";
import Banner from "../components/Banner";
import MissionCard from "../components/MissionCard";
import VisionIcon from "../assets/VisionIcon.svg";
import MissionIcon from "../assets/MissionIcon.svg";
import ValuesIcon from "../assets/ValuesIcon.svg";
import gallery from "../assets/gallery.png";
import Services from "../components/Services";
import AboutUsCards from "../components/AboutUsCards";
import { motion, useIsPresent } from "framer-motion";


const AboutUs = () => {
	const App = (mode) => (
    <div className="w-full">
      <motion.div
        initial={{ opacity: 0, y: "100%" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "100%" }}
        transition={{ duration: 0.5 }}
      >
        <Banner title="About Us" mode={mode} />
        <div className="px-5 md:px-16 lg:px-44" mode={mode}>
          <p
            className={`py-10 font-normal md:max-w-xl lg:max-w-3xl lg:py-20 lt ${mode}`}
          >
            SkywardOps is a cutting-edge technology company that offers cloud
            services, DevOps, and software development solutions. With a focus
            on innovation, SkywardOps provides efficient and effective solutions
            to businesses looking to streamline their operations and improve
            their technological capabilities. SkywardOps is committed to staying
            ahead of the curve and providing the latest and greatest technology
            solutions to their clients.
          </p>
          <div className="flex flex-col gap-5 mb-20 lg:flex-row md:mb-28 lg:mb-80">
            <MissionCard
              icon={MissionIcon}
              title="Our Mission"
              text="We empower businesses with cutting-edge technology solutions"
              mode={mode}
            />
            <MissionCard
              icon={VisionIcon}
              title="Our Vision"
              text="Leading innovation in tech landscape"
              mode={mode}
            />
            <MissionCard
              icon={ValuesIcon}
              title="Our Values"
              text="Innovation, Adaptability, Integrity, Expertise, and Commitment"
              mode={mode}
            />
          </div>
          <AboutUsCards title="Management Team" gallery={gallery} mode={mode} />
          <AboutUsCards
            title="Board of Directors"
            gallery={gallery}
            mode={mode}
          />
          <div className="mt-20 md:mt-32 lg:mt-56">
            <h2
              className={`about-headers ${mode} font-bold text-[27px] md:text-4xl mb-3`}
              mode={mode}
            >
              Our Partners & Clients
            </h2>
          </div>
        </div>
        <div className="py-[33px] md:py-[75px]">
          <Services mode={mode} />
        </div>
      </motion.div>
    </div>
  );

	return (
    <div>
      <Main App={App} location="us" />
    </div>
  );
};

export default AboutUs;
