/** @format */

import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Main from "../containers/Main";
import { urlFor, client } from "../client";
import Banner from "../components/Banner";
import { useNavigate } from "react-router-dom";
import Arrow from "../assets/Arrow.svg";
import ArrowRignt from "../assets/arrow-right.svg";
import ProjectButton from "../components/ProjectButton";
import { motion, useIsPresent } from "framer-motion";


const Project = () => {
  const { id } = useParams();
  const [next_id, setNext_id] = useState(id);
  const navigate = useNavigate();
  const [project, setProject] = useState([]);
  const location = useLocation();
  const { data } = location.state;
const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
useEffect(() => {
  console.log(data);
  const query = `*[_type == "portfolio" && _id == "${id}"]`;

  client.fetch(query).then((data) => {
    console.log(data);
    setProject(data);
  });

  NextProject();
}, [id]);

  const NextProject = () => {
    for (let i = 0; i < data.length; i++) {
      if (i === data.length - 1) {
        return setNext_id(data[0]._id);
      } else if (data[i]._id === id) {
        return setNext_id(data[i + 1]._id);
      }
    }
  };

  const App = (mode) => (
    <div>
      <motion.div
        initial={{ opacity: 0, y: "100%" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "100%" }}
        transition={{ duration: 0.5 }}
      >
        <Banner title={project[0]?.title} mode={mode} />
        <div className="m-5 md:mx-16 md:mt-10 md:mb-20 lg:mt-20 lg:mx-44 ">
          <div
            className={`project-image ${mode} my-4 md:my-10 lg:mt-20 w-full flex justify-center items-center rounded-xl w-2/3 h-[239px] lg:h-[700px]`}
          >
            {project[0] && project[0]?.featuredImage ? (
              <img
                src={urlFor(project[0]?.featuredImage).url()}
                alt="Project Image"
                className="w-full h-full object-cover"
                style={{}}
              />
            ) : null}
          </div>
          <div>
            <h2
              className={`project-desc ${mode} text-xl md:text-4xl lg:text-5xl mb-2 mt-[16px] md:mt-[40px]`}
            >
              {project[0]?.title}
            </h2>
            <div className="mt-[8px] md:mt-[21px] lg:mt-[8px] w-full">
              <h3
                className={`project-desc ${mode} text-[21px] md:text-[27px] font-bold`}
              >
                Technologies Used
              </h3>
              <ol
                className={`project-desc ${mode} mt-[8px] md:w-4/5 md:flex md:flex-wrap justify-between`}
              >
                {project[0]?.technologies?.map((tech, index) => (
                  <li
                    key={index}
                    className="md:w-1/2 md:text-[21px] my-3 flex  items-center"
                  >
                    <span className="w-[3px] h-[3px] mr-3 bg-black rounded-full "></span>
                    <p>{tech}</p>
                  </li>
                ))}
              </ol>
            </div>

            <div className="mt-[38px] md:mt-[40px]">
              <h3
                className={`project-desc ${mode} text-[27px] md:text-[27px] font-bold`}
              >
                Overview:
              </h3>
              <p className={`project-desc ${mode} md:text-[21px] mt-[8px]`}>
                {project[0]?.overview}
              </p>
            </div>

            <div className="mt-[38px] md:mt-[40px]">
              <h3
                className={`project-desc ${mode} text-[27px] md:text-[27px] font-bold`}
              >
                Project Steps
              </h3>
              <div className="mt-[40px] md:mt-[30px]">
                {project[0]?.projectSteps?.map((step, index) => (
                  <div key={index}>
                    <h3
                      className={`project-desc ${mode} font-bold md:text-[21px] mt-[8px] flex items-center`}
                    >
                      <span className=" mr-1 ">{index + 1}.</span>
                      <p>{step.stepTitle}</p>
                    </h3>
                    {step?.stepDescriptions?.map((data, index) => (
                      <li className={`flex items-center project-desc ${mode}`}>
                        <span className="w-[3px] h-[3px] mr-3 bg-black rounded-full"></span>
                        <p>{data}</p>
                      </li>
                    ))}
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-[38px] md:mt-[40px] mb-10">
              <h3
                className={`project-desc ${mode} text-[27px] md:text-[27px] font-bold`}
              >
                Outcome:
              </h3>
              <p className={`project-desc ${mode} md:text-[21px] mt-[8px]`}>
                {project[0]?.outcomeDescription ||
                  "No outcome description available."}
              </p>
            </div>
          </div>
          {/* <div
            className={`link ${mode} mt-4 mb-6 md:mb-10 lg:mt-10 lg:mb-20 flex justify-between`}
          >
            {project[0]?.projectLink ? (
              <Link
                to={project[0].projectLink}
                className={`font-bold lg:text-xl project-link ${mode}`}
              >
                Project URL
              </Link>
            ) : (
              <p className={`font-bold lg:text-xl project-link ${mode}`}>
                Project URL not available
              </p>
            )}
            <img src={Arrow} alt="arrow" className={`arrow-icon ${mode}`} />
          </div> */}
          <Link
            to={`/project/${next_id}`}
            state={{ data: data }}
            onClick={scrollToTop}
          >
            <div>
              <ProjectButton
                text="View Next Project"
                icon={ArrowRignt}
                mode={mode}
                url={`/project/${next_id}`}
                fullWidth
              />
            </div>
          </Link>
        </div>
      </motion.div>
    </div>
  );
  return (
    <div>
      <Main App={App} />
    </div>
  );
};

export default Project;
