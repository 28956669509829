/** @format */

import React, { useState, useEffect } from "react";
import darkLogo from "../assets/logos/darkLogo.svg";
import lightLogo from "../assets/logos/lightLogo.svg";
import { useDispatch } from "react-redux";
import { SwitchMode } from "../redux/actions/user";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const Header = ({ mode }) => {
  const dispatch = useDispatch();
  const locationn = useLocation();

  const [isHamburgerMenu, setIsHamburgerMenu] = useState(false);

  const [active, setActive] = useState(9);
  console.log(locationn);
  // const loc = useSelector((state) => state.user.location);
  const [location, setLocation] = useState("us");
  const Style = {
    active: {
      color: mode === "light" ? "#008080" : "#15ECE2",
      fontWeight: "700",
    },
    inactive: { color: mode === "light" ? "#262626" : "#F6F6F6" },
  };

  useEffect(() => setLocation(localStorage.getItem("loc")), []);

  useEffect(() => {
    setActive(locationn.state?.active);
  }, [location]);
  const variants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: "100%" },
  };

  const scrollToTop = () => {
    // setActive(prop);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      className={`App ${mode} w-full flex items-center justify-between px-5 lg:px-20  py-5 text-[16px] fixed top-0 left-0 right-0 z-50`}
      id="Top"
    >
      <Link to={location === "ng" ? "/" : "/"}>
        <div>
          <img
            src={mode === "light" ? lightLogo : darkLogo}
            alt="logo"
            width="204px"
            height="48px"
          />
        </div>
      </Link>

      <div className={`hidden xl:flex items-center justify-between w-3/6 `}>
        <Link
          to={location === "ng" ? "/about" : "/aboutus"}
          state={{ active: 0 }}
          onClick={scrollToTop}
        >
          <p
            className="cursor-pointer hover:text-[red] active:text-[blue] focus:[green]"
            style={active === 0 ? Style.active : Style.inactive}
          >
            About Us
          </p>
        </Link>
        <Link to="/portfolio" state={{ active: 1 }} onClick={scrollToTop}>
          <p
            className="cursor-pointer"
            style={active === 1 ? Style.active : Style.inactive}
          >
            Our Portfolio
          </p>
        </Link>
        <Link to="/blog" state={{ active: 2 }} onClick={scrollToTop}>
          <p
            className="cursor-pointer"
            style={active === 2 ? Style.active : Style.inactive}
          >
            Blog
          </p>
        </Link>
        <Link to="/careers" state={{ active: 3 }} onClick={scrollToTop}>
          <p
            className="cursor-pointer"
            style={active === 3 ? Style.active : Style.inactive}
          >
            Careers
          </p>
        </Link>
        <Link
          to={location === "ng" ? "/contacts" : "/contactus"}
          state={{ active: 9 }}
          onClick={scrollToTop}
        >
          <div
            className={
              mode === "dark"
                ? "rounded-[12px] p-3 text-[#333] bg-[#15ECE2] cursor-pointer font-bold"
                : "rounded-[12px] p-3 text-[#fff] bg-[#333] cursor-pointer font-bold"
            }
            // onClick={() => scrollToTop()}
          >
            Contact Us
          </div>
        </Link>

        <div
          className=" flex items-center cursor-pointer"
          onClick={() =>
            mode === "light"
              ? SwitchMode(dispatch, "dark", location)
              : SwitchMode(dispatch, "light", location)
          }
        >
          {mode === "light" ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.4604 22.7501C12.2904 22.7501 12.1204 22.7501 11.9504 22.7401C6.35044 22.4901 1.67044 17.9801 1.28044 12.4801C0.940437 7.76011 3.67044 3.35011 8.07044 1.50011C9.32044 0.980114 9.98044 1.38011 10.2604 1.67011C10.5404 1.95011 10.9304 2.60011 10.4104 3.79011C9.95044 4.85011 9.72044 5.98011 9.73044 7.14011C9.75044 11.5701 13.4304 15.3301 17.9204 15.5101C18.5704 15.5401 19.2104 15.4901 19.8304 15.3801C21.1504 15.1401 21.7004 15.6701 21.9104 16.0101C22.1204 16.3501 22.3604 17.0801 21.5604 18.1601C19.4404 21.0601 16.0704 22.7501 12.4604 22.7501ZM2.77044 12.3701C3.11044 17.1301 7.17044 21.0301 12.0104 21.2401C15.3004 21.4001 18.4204 19.9001 20.3404 17.2801C20.4904 17.0701 20.5604 16.9201 20.5904 16.8401C20.5004 16.8301 20.3404 16.8201 20.0904 16.8701C19.3604 17.0001 18.6004 17.0501 17.8504 17.0201C12.5704 16.8101 8.25044 12.3801 8.22044 7.16011C8.22044 5.78011 8.49044 4.45011 9.04044 3.20011C9.14044 2.98011 9.16044 2.83011 9.17044 2.75011C9.08044 2.75011 8.92044 2.77011 8.66044 2.88011C4.85044 4.48011 2.49044 8.30011 2.77044 12.3701Z"
                fill="#262626"
              />
            </svg>
          ) : (
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 19.75C8 19.75 4.75 16.5 4.75 12.5C4.75 8.5 8 5.25 12 5.25C16 5.25 19.25 8.5 19.25 12.5C19.25 16.5 16 19.75 12 19.75ZM12 6.75C8.83 6.75 6.25 9.33 6.25 12.5C6.25 15.67 8.83 18.25 12 18.25C15.17 18.25 17.75 15.67 17.75 12.5C17.75 9.33 15.17 6.75 12 6.75Z"
                fill="white"
              />
              <path
                d="M12 23.46C11.45 23.46 11 23.05 11 22.5V22.42C11 21.87 11.45 21.42 12 21.42C12.55 21.42 13 21.87 13 22.42C13 22.97 12.55 23.46 12 23.46ZM19.14 20.64C18.88 20.64 18.63 20.54 18.43 20.35L18.3 20.22C17.91 19.83 17.91 19.2 18.3 18.81C18.69 18.42 19.32 18.42 19.71 18.81L19.84 18.94C20.23 19.33 20.23 19.96 19.84 20.35C19.65 20.54 19.4 20.64 19.14 20.64ZM4.86 20.64C4.6 20.64 4.35 20.54 4.15 20.35C3.76 19.96 3.76 19.33 4.15 18.94L4.28 18.81C4.67 18.42 5.3 18.42 5.69 18.81C6.08 19.2 6.08 19.83 5.69 20.22L5.56 20.35C5.37 20.54 5.11 20.64 4.86 20.64ZM22 13.5H21.92C21.37 13.5 20.92 13.05 20.92 12.5C20.92 11.95 21.37 11.5 21.92 11.5C22.47 11.5 22.96 11.95 22.96 12.5C22.96 13.05 22.55 13.5 22 13.5ZM2.08 13.5H2C1.45 13.5 1 13.05 1 12.5C1 11.95 1.45 11.5 2 11.5C2.55 11.5 3.04 11.95 3.04 12.5C3.04 13.05 2.63 13.5 2.08 13.5ZM19.01 6.49C18.75 6.49 18.5 6.39 18.3 6.2C17.91 5.81 17.91 5.18 18.3 4.79L18.43 4.66C18.82 4.27 19.45 4.27 19.84 4.66C20.23 5.05 20.23 5.68 19.84 6.07L19.71 6.2C19.52 6.39 19.27 6.49 19.01 6.49ZM4.99 6.49C4.73 6.49 4.48 6.39 4.28 6.2L4.15 6.06C3.76 5.67 3.76 5.04 4.15 4.65C4.54 4.26 5.17 4.26 5.56 4.65L5.69 4.78C6.08 5.17 6.08 5.8 5.69 6.19C5.5 6.39 5.24 6.49 4.99 6.49ZM12 3.54C11.45 3.54 11 3.13 11 2.58V2.5C11 1.95 11.45 1.5 12 1.5C12.55 1.5 13 1.95 13 2.5C13 3.05 12.55 3.54 12 3.54Z"
                fill="white"
              />
            </svg>
          )}
          <p className="mx-1">Switch to {mode === "light" ? "Night" : "Day"}</p>
        </div>
      </div>
      <div className="flex xl:hidden w-[40px]">
        {isHamburgerMenu ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            onClick={() => setIsHamburgerMenu(false)}
          >
            <path
              d="M19.9997 37.9168C10.1163 37.9168 2.08301 29.8835 2.08301 20.0002C2.08301 10.1168 10.1163 2.0835 19.9997 2.0835C29.883 2.0835 37.9163 10.1168 37.9163 20.0002C37.9163 29.8835 29.883 37.9168 19.9997 37.9168ZM19.9997 4.5835C11.4997 4.5835 4.58301 11.5002 4.58301 20.0002C4.58301 28.5002 11.4997 35.4168 19.9997 35.4168C28.4997 35.4168 35.4163 28.5002 35.4163 20.0002C35.4163 11.5002 28.4997 4.5835 19.9997 4.5835Z"
              fill="#008080"
            />
            <path
              d="M15.2829 25.9668C14.9663 25.9668 14.6496 25.8501 14.3996 25.6001C13.9163 25.1168 13.9163 24.3168 14.3996 23.8334L23.8329 14.4001C24.3163 13.9168 25.1163 13.9168 25.5996 14.4001C26.0829 14.8834 26.0829 15.6834 25.5996 16.1668L16.1663 25.6001C15.9329 25.8501 15.5996 25.9668 15.2829 25.9668Z"
              fill="#008080"
            />
            <path
              d="M24.7163 25.9668C24.3996 25.9668 24.0829 25.8501 23.8329 25.6001L14.3996 16.1668C13.9163 15.6834 13.9163 14.8834 14.3996 14.4001C14.8829 13.9168 15.6829 13.9168 16.1663 14.4001L25.5996 23.8334C26.0829 24.3168 26.0829 25.1168 25.5996 25.6001C25.3496 25.8501 25.0329 25.9668 24.7163 25.9668Z"
              fill="#008080"
            />
          </svg>
        ) : (
          <svg
            width="56"
            height="48"
            viewBox="0 0 56 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setIsHamburgerMenu(true)}
          >
            <path
              d="M37 20H19C18.5795 20 18 19.5467 18 19C18 18.4533 18.5795 18 19 18H37C37.4205 18 38 18.4533 38 19C38 19.5467 37.4205 20 37 20Z"
              fill="#008080"
            />
            <path
              d="M37 25H19C18.5795 25 18 24.5467 18 24C18 23.4533 18.5795 23 19 23H37C37.4205 23 38 23.4533 38 24C38 24.5467 37.4205 25 37 25Z"
              fill="#008080"
            />
            <path
              d="M37 30H19C18.5795 30 18 29.5467 18 29C18 28.4533 18.5795 28 19 28H37C37.4205 28 38 28.4533 38 29C38 29.5467 37.4205 30 37 30Z"
              fill="#008080"
            />
            <rect
              x="0.5"
              y="0.5"
              width="55"
              height="47"
              rx="7.5"
              stroke="#008080"
            />
          </svg>
        )}
      </div>
      <div
        className="xl:hidden fixed w-2/3 z-50 px-2 py-32 bg-white -top-5 h-[120%] text-left left-0 right-0"
        style={{
          visibility: isHamburgerMenu ? "visible" : "hidden",
          text: mode === "light" ? "black" : "white",
          background: mode === "light" ? "white" : "#333",
        }}
      >
        <ul>
          <motion.nav
            animate={isHamburgerMenu ? "open" : "closed"}
            variants={variants}
          >
            <Link to={location === "ng" ? "/about" : "/aboutus"}>
              <li className="text-[21px] mb-10" onClick={() => scrollToTop(0)}>
                About Us
              </li>
            </Link>
            <Link to="/portfolio">
              <li className="text-[21px] mb-10" onClick={() => scrollToTop(0)}>
                Our Portfolio
              </li>
            </Link>
            <Link to="/blog">
              <li className="text-[21px] mb-10" onClick={() => scrollToTop(0)}>
                Blog
              </li>
            </Link>
            <Link to="/careers">
              <li className="text-[21px] mb-10" onClick={() => scrollToTop(0)}>
                Careers
              </li>
            </Link>

            <li
              className="text-[21px] mb-10 w-full"
              onClick={() => scrollToTop(0)}
            >
              <Link to={location === "ng" ? "/contacts" : "/contactus"}>
                <div
                  className={
                    mode === "dark"
                      ? "rounded-[12px] p-3 text-[#333] bg-[#15ECE2] cursor-pointer font-bold text-center"
                      : "rounded-[12px] p-3 text-[#fff] bg-[#333] cursor-pointer font-bold text-center"
                  }
                >
                  Contact Us
                </div>
              </Link>
            </li>
            <li className="text-[21px] mb-10">
              <div
                className=" flex items-center cursor-pointer "
                onClick={() =>
                  mode === "light"
                    ? SwitchMode(dispatch, "dark", location)
                    : SwitchMode(dispatch, "light", location)
                }
              >
                {mode === "light" ? (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-[50px]"
                  >
                    <path
                      d="M12.4604 22.7501C12.2904 22.7501 12.1204 22.7501 11.9504 22.7401C6.35044 22.4901 1.67044 17.9801 1.28044 12.4801C0.940437 7.76011 3.67044 3.35011 8.07044 1.50011C9.32044 0.980114 9.98044 1.38011 10.2604 1.67011C10.5404 1.95011 10.9304 2.60011 10.4104 3.79011C9.95044 4.85011 9.72044 5.98011 9.73044 7.14011C9.75044 11.5701 13.4304 15.3301 17.9204 15.5101C18.5704 15.5401 19.2104 15.4901 19.8304 15.3801C21.1504 15.1401 21.7004 15.6701 21.9104 16.0101C22.1204 16.3501 22.3604 17.0801 21.5604 18.1601C19.4404 21.0601 16.0704 22.7501 12.4604 22.7501ZM2.77044 12.3701C3.11044 17.1301 7.17044 21.0301 12.0104 21.2401C15.3004 21.4001 18.4204 19.9001 20.3404 17.2801C20.4904 17.0701 20.5604 16.9201 20.5904 16.8401C20.5004 16.8301 20.3404 16.8201 20.0904 16.8701C19.3604 17.0001 18.6004 17.0501 17.8504 17.0201C12.5704 16.8101 8.25044 12.3801 8.22044 7.16011C8.22044 5.78011 8.49044 4.45011 9.04044 3.20011C9.14044 2.98011 9.16044 2.83011 9.17044 2.75011C9.08044 2.75011 8.92044 2.77011 8.66044 2.88011C4.85044 4.48011 2.49044 8.30011 2.77044 12.3701Z"
                      fill="#262626"
                    />
                  </svg>
                ) : (
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 19.75C8 19.75 4.75 16.5 4.75 12.5C4.75 8.5 8 5.25 12 5.25C16 5.25 19.25 8.5 19.25 12.5C19.25 16.5 16 19.75 12 19.75ZM12 6.75C8.83 6.75 6.25 9.33 6.25 12.5C6.25 15.67 8.83 18.25 12 18.25C15.17 18.25 17.75 15.67 17.75 12.5C17.75 9.33 15.17 6.75 12 6.75Z"
                      fill="white"
                    />
                    <path
                      d="M12 23.46C11.45 23.46 11 23.05 11 22.5V22.42C11 21.87 11.45 21.42 12 21.42C12.55 21.42 13 21.87 13 22.42C13 22.97 12.55 23.46 12 23.46ZM19.14 20.64C18.88 20.64 18.63 20.54 18.43 20.35L18.3 20.22C17.91 19.83 17.91 19.2 18.3 18.81C18.69 18.42 19.32 18.42 19.71 18.81L19.84 18.94C20.23 19.33 20.23 19.96 19.84 20.35C19.65 20.54 19.4 20.64 19.14 20.64ZM4.86 20.64C4.6 20.64 4.35 20.54 4.15 20.35C3.76 19.96 3.76 19.33 4.15 18.94L4.28 18.81C4.67 18.42 5.3 18.42 5.69 18.81C6.08 19.2 6.08 19.83 5.69 20.22L5.56 20.35C5.37 20.54 5.11 20.64 4.86 20.64ZM22 13.5H21.92C21.37 13.5 20.92 13.05 20.92 12.5C20.92 11.95 21.37 11.5 21.92 11.5C22.47 11.5 22.96 11.95 22.96 12.5C22.96 13.05 22.55 13.5 22 13.5ZM2.08 13.5H2C1.45 13.5 1 13.05 1 12.5C1 11.95 1.45 11.5 2 11.5C2.55 11.5 3.04 11.95 3.04 12.5C3.04 13.05 2.63 13.5 2.08 13.5ZM19.01 6.49C18.75 6.49 18.5 6.39 18.3 6.2C17.91 5.81 17.91 5.18 18.3 4.79L18.43 4.66C18.82 4.27 19.45 4.27 19.84 4.66C20.23 5.05 20.23 5.68 19.84 6.07L19.71 6.2C19.52 6.39 19.27 6.49 19.01 6.49ZM4.99 6.49C4.73 6.49 4.48 6.39 4.28 6.2L4.15 6.06C3.76 5.67 3.76 5.04 4.15 4.65C4.54 4.26 5.17 4.26 5.56 4.65L5.69 4.78C6.08 5.17 6.08 5.8 5.69 6.19C5.5 6.39 5.24 6.49 4.99 6.49ZM12 3.54C11.45 3.54 11 3.13 11 2.58V2.5C11 1.95 11.45 1.5 12 1.5C12.55 1.5 13 1.95 13 2.5C13 3.05 12.55 3.54 12 3.54Z"
                      fill="white"
                    />
                  </svg>
                )}
                <p className="mx-1 text-[24px]">
                  Switch to {mode === "light" ? "Night" : "Day"}
                </p>
              </div>
            </li>
          </motion.nav>
        </ul>
      </div>
    </div>
  );
};
export default Header;
