/** @format */

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import * as React from "react";
import "./App.css";
import { AnimatePresence } from "framer-motion";
import { useLocation, useRoutes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Main from "./containers/Main";
import LandingPage from "./pages/LandingPage";
import AboutUs from "./pages/AboutUs";
import Portfolio from "./pages/Portfolio";
import Project from "./pages/Project";
import Blog from "./pages/Blog";
import Careers from "./pages/Careers";
import ContactUs from "./pages/ContactUs";
import Privacy from "./pages/Privacy";
import CareersForm from "./pages/CareersForm";
import FAQ from "./pages/FAQ";
import UsaLandingPage from "./pages/UsaLandingPage";
import UsaAboutUs from "./pages/UsaAboutUs";
import UsaContactUs from "./pages/UsaContactUs";
import ThankYou from "./pages/ThankYou";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/landing" element={<LandingPage />} /> */}
        <Route path="/" element={<UsaLandingPage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/aboutus" element={<UsaAboutUs />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/project/:id" element={<Project />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/:id" element={<CareersForm />} />
        <Route path="/contacts" element={<ContactUs />} />
        <Route path="/contactus" element={<UsaContactUs />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/success" element={<ThankYou />} />
      </Routes>
    </Router>
  );
}

export default App;
