import React from 'react';

const Management = ({ image, name, position, mode }) => {
  return (
    <div
      className={`management-card ${mode} w-[256px] lg:w-[256px] md:w-[340px] h-[350px] rounded-xl overflow-hidden flex flex-col flex-shrink-0`}
    >
      <div className="flex-grow flex items-center justify-center">
        <img src={image} alt={name} className="max-h-full max-w-full" />
      </div>
      <div className="bg-[#333] text-white px-4 py-4 rounded-xl">
        <h3 className="mb-1 font-bold">{name}</h3>
        <span className="font-normal text-xs">{position}</span>
      </div>
    </div>
  );
};

export default Management;
