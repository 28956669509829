/** @format */

import React, { useState, useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

const Map = ({location}) => {
  mapboxgl.accessToken =
    "pk.eyJ1IjoiYWxhZGVmYXJ1ayIsImEiOiJja3BweDV6aDgzZGE1MndyaTVrOGxtNGZoIn0.imNS_qbDwkmeO2VBKoZPWA";
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [zoom, setZoom] = useState(15);

  const lng = 3.85033;
  const lat = 6.46305;

  const USlng = -100.0;
  const USlat = 31.0;
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: location === "ng" ? [lng, lat] : [USlng, USlat],
      zoom: zoom,
    });
  });

  return (
    <div className="w-full h-[472px] md:h-[894px] lg:h-[800px]  overflow-hidden">
      <div ref={mapContainer} className="h-full" />
    </div>
  );
};

export default Map;
