/** @format */

import React from "react";
// import { changeCategory } from "../redux/actions/category";
// import { useDispatch, useSelector } from "react-redux";

const Pill = ({ active, title }) => {
  //   let dispatch = useDispatch();
  //   let CategoryHeader = useSelector((state) => state.category.category);

  const activeStyle = {
    borderRadius: "12px",
    background: "#4AFEF0",
    color: "#003235",
    border: "1px solid #4AFEF0",
    fontWeight: "700",
  };

  const inactiveStyle = {
    borderRadius: "12px",
    background: "#F6F6F6",
    color: "#B0B0B0",
    border: "1px solid #F6F6F6",
  };

  return (
    <div
      style={active ? activeStyle : inactiveStyle}
      className="px-[16px] py-[8px] mx-2.5 cursor-pointer text-[10px] md:text-[16px]"
    >
      {title}
    </div>
  );
};

export default Pill;
