import React from 'react';
import backgroundImage from '../assets/banner-background.png';

const Banner = ({ title, mode }) => {
  return (
    <div
      className={`px-5 banner ${mode} py-9 md:px-16 md:py-14 lg:px-44 lg:py-24`}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <h1 className="font-bold text-3xl  md:text-6xl lg:text-7xl">{title}</h1>
    </div>
  );
};

export default Banner;
