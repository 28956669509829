import React, { useState, useEffect } from "react";
import Main from "../containers/Main";
import { urlFor, client } from "../client";
import Banner from "../components/Banner";
import ProjectButton from "../components/ProjectButton";
import { Link } from "react-router-dom";
import Pagination from "../components/Pagination";
import Pill from "../components/Pill";
import { motion, useIsPresent } from "framer-motion";


const Careers = () => {
	const [careers, setCareers] = useState([]);
	const [allCareers, setAllCareers] = useState([]);
	const [totalNo, setTotal] = useState(0);
	const [index, setIndex] = useState([0, 4]);
	const [selectedTag, setSelectedTag] = useState("all");
	const [mobileFilter, setMobileFilter] = useState(false);

	const CareerTags = [
		{
			text: "View all",
			tag: "all",
		},
		{
			text: "Cloud",
			tag: "cloud",
		},
		{
			text: "Development",
			tag: "development",
		},
		{
			text: "Design",
			tag: "design",
		},
		{
			text: "Documentation",
			tag: "documentation",
		},
	];

	useEffect(() => {
		const query = '*[_type == "careers"]{..., tags}';
		client.fetch(query).then((data) => {
			const careers = data.map((item) => {
				const tags = Array.isArray(item.tags)
					? item.tags.map((tag) => tag) // Use tag directly since it's a string
					: [];

				return {
					...item,
					tags: tags,
				};
			});

			setCareers(careers);
			setAllCareers(careers); // Save the original data
			setTotal(careers.length);
		});
	}, []);

	const handleCareerFilter = (tag) => {
		setSelectedTag(tag);

		if (tag === "all") {
			// Reset to all careers
			setCareers(allCareers);
		} else {
			setCareers(allCareers.filter((career) => career.tags.includes(tag)));
		}
	};

	const App = (mode) => (
    <div>
      <motion.div
        initial={{ opacity: 0, y: "100%" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "100%" }}
        transition={{ duration: 0.5 }}
      >
        <Banner title="Careers" mode={mode} />
        <div className="m-5 md:mx-16 md:mt-10 md:mb-20 lg:mt-20 lg:mx-44">
          <div
            className={`mb-10 md:mb-12 lg:mb-[70px] header-color ${mode}`}
            mode={mode}
          >
            <h1 className="font-bold text-[28px] md:text-5xl lg:text-6xl mb-2 md:mb-4">
              Join Our Team
            </h1>
            <p className="text-base md:text-xl lg:w-1/2">
              We’re looking for professional individuals looking to expand their
              knowledge and experience.
            </p>
          </div>
          <div className="hidden md:flex items-center w-full lg:w-4/5 my-4 mb-10 md:mb-12 overflow-x-scroll">
            {CareerTags.map((item, index) => (
              <div key={index} onClick={() => handleCareerFilter(item.tag)}>
                <Pill
                  active={selectedTag === item.tag}
                  title={item.text}
                  tag={item.tag}
                />
              </div>
            ))}
          </div>

          <div className=" md:hidden items-center w-full my-4 mb-10 md:mb-12 relative">
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                onClick={() => setMobileFilter(mobileFilter ? false : true)}
              >
                <path
                  d="M10.9404 22.65C10.4604 22.65 9.99039 22.53 9.55039 22.29C8.67039 21.8 8.14039 20.91 8.14039 19.91V14.61C8.14039 14.11 7.81039 13.36 7.50039 12.98L3.76039 9.02001C3.13039 8.39001 2.65039 7.31001 2.65039 6.50001V4.20001C2.65039 2.60001 3.86039 1.35001 5.40039 1.35001H18.6004C20.1204 1.35001 21.3504 2.58001 21.3504 4.10001V6.30001C21.3504 7.35001 20.7204 8.54001 20.1304 9.13001L15.8004 12.96C15.3804 13.31 15.0504 14.08 15.0504 14.7V19C15.0504 19.89 14.4904 20.92 13.7904 21.34L12.4104 22.23C11.9604 22.51 11.4504 22.65 10.9404 22.65ZM5.40039 2.85001C4.70039 2.85001 4.15039 3.44001 4.15039 4.20001V6.50001C4.15039 6.87001 4.45039 7.59001 4.83039 7.97001L8.64039 11.98C9.15039 12.61 9.65039 13.66 9.65039 14.6V19.9C9.65039 20.55 10.1004 20.87 10.2904 20.97C10.7104 21.2 11.2204 21.2 11.6104 20.96L13.0004 20.07C13.2804 19.9 13.5604 19.36 13.5604 19V14.7C13.5604 13.63 14.0804 12.45 14.8304 11.82L19.1104 8.03001C19.4504 7.69001 19.8604 6.88001 19.8604 6.29001V4.10001C19.8604 3.41001 19.3004 2.85001 18.6104 2.85001H5.40039Z"
                  fill="#008080"
                />
                <path
                  d="M5.99968 10.75C5.85968 10.75 5.72968 10.71 5.59968 10.64C5.24968 10.42 5.13968 9.94999 5.35968 9.59999L10.2897 1.69999C10.5097 1.34999 10.9697 1.23999 11.3197 1.45999C11.6697 1.67999 11.7797 2.13999 11.5597 2.48999L6.62968 10.39C6.48968 10.62 6.24968 10.75 5.99968 10.75Z"
                  fill="#008080"
                />
              </svg>
              <p className="text-[#008080] text-[16px] font-bold ml-2">
                Filter
              </p>
            </div>
            {mobileFilter && (
              <div
                className="z-20 h-100 w-100 absolute top-10 bg-black rounded-md py-5"
                style={{ background: "#F6F6F6" }}
              >
                {CareerTags.map((item, index) => (
                  <div key={index} onClick={() => handleCareerFilter(item.tag)}>
                    <Pill
                      active={selectedTag === item.tag}
                      title={item.text}
                      tag={item.tag}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-wrap w-full">
            {careers.slice(index[0], index[1]).map((career, index) => (
              <div
                className={`content-color ${mode} w-full md:w-1/2 mb-14 md:mb-14 pr-12`}
                key={index}
              >
                <h1 className="font-bold text-xl md:text-3xl">
                  {career.title}
                </h1>
                <p className="text-base md:text-xl my-2">
                  {career.type} - {career.employmentType},{" "}
                  {new Date(career.startDate).toLocaleDateString("en-US", {
                    month: "long",
                    year: "numeric",
                  })}
                </p>

                <p className="text-base mb-4">
                  {career.description.slice(0, 150)}{" "}
                  {career.description > 150 ? "..." : ""}
                </p>
                <Link to={`/careers/${career._id}`}>
                  <ProjectButton text="Apply For Role" mode={mode} />
                </Link>
              </div>
            ))}
          </div>
          <Pagination
            data={careers}
            totalNo={totalNo}
            mode={mode}
            onClick={(prop) => {
              setIndex(prop);
            }}
          />
        </div>
      </motion.div>
    </div>
  );

	return (
		<div>
			<Main App={App} />
		</div>
	);
};

export default Careers;
