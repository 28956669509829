import React from "react";
import Main from "../containers/Main";
import Banner from "../components/Banner";
import PrivacyInfoContainer from "../components/PrivacyInfoContainer";
import { motion, useIsPresent } from "framer-motion";


const Privacy = () => {
	const App = (mode) => (
    <div>
      <motion.div
        initial={{ opacity: 0, y: "100%" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "100%" }}
        transition={{ duration: 0.5 }}
      >
        <Banner title="Privacy Policy" mode={mode} />
        <div className="m-5 md:mx-16 md:mt-10 md:mb-20 lg:mt-20 lg:mx-44">
          <p
            className={`privacy-color ${mode} text-xl md:text-[27px] mb-10 lg:mb-20`}
            mode={mode}
          >
            Last updated: [Sep 2023]
          </p>
          <div>
            <p
              className={`privacy-color ${mode} md:text-xl mb-6 lg:mb-10`}
              mode={mode}
            >
              At SkywardOps, we prioritize your privacy. This privacy policy
              outlines the types of personal information we collect, how we use
              it, and the safeguards we have in place to protect it.
            </p>
            <PrivacyInfoContainer mode={mode} />
          </div>
        </div>
      </motion.div>
    </div>
  );
	return (
		<div>
			<Main App={App} />
		</div>
	);
};

export default Privacy;
