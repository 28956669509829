/** @format */

import React, { useState,useRef } from "react";
import Title from "./Title";
import WhatWeDoCard from "./WhatWeDoCard";
// import { WhatWeDo as Data } from "../data/data";
import CS from "../assets/cloud-storage.gif";
import CC from "../assets/cloud-computing.gif";
import DCE from "../assets/data-center-eng.gif";
import CT from "../assets/cloud-tech.gif";

const WhatWeDo = ({ mode, location }) => {
  const [active, setActive] = useState(0);
  const [currentItem, setCurrentItem] = useState(1);
  const scrollRef = useRef(null);

  const itemCount = 5; // Set the total number of items in your carousel
  const itemWidth = 300; // Set the width of each item in your carousel (adjust as needed)
  const previousScrollX = 0;

  const handleScroll = () => {
    const scrollX = scrollRef.current.scrollLeft;
    setCurrentItem(Math.floor(scrollX / itemWidth));
  };
  const Data = [
    {
      image: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M16.0598 16.7502C16.0498 16.7502 16.0398 16.7502 16.0198 16.7502H6.3598C3.7298 16.5702 2.5498 14.5502 2.5498 12.7502C2.5498 11.1702 3.45982 9.42019 5.43982 8.90019C4.91982 6.73019 5.39981 4.70013 6.80981 3.22013C8.44981 1.50013 11.0698 0.820184 13.3398 1.52018C15.3998 2.15018 16.8498 3.83014 17.3898 6.16014C19.1798 6.59014 20.6098 7.95018 21.1798 9.83018C21.8098 11.8902 21.2298 14.0001 19.6798 15.3501C18.7098 16.2501 17.4198 16.7502 16.0598 16.7502ZM6.39981 10.2602C4.78981 10.3902 4.06982 11.6002 4.06982 12.7502C4.06982 13.9102 4.79981 15.1302 6.42981 15.2502H16.0398C16.0498 15.2502 16.0598 15.2502 16.0698 15.2502C17.0498 15.2502 17.9898 14.8901 18.6998 14.2301C20.0198 13.0801 20.1098 11.4302 19.7598 10.2602C19.3998 9.09017 18.4098 7.77015 16.6698 7.55015C16.3398 7.51015 16.0798 7.26016 16.0198 6.93016C15.6798 4.88016 14.5698 3.47019 12.9098 2.96019C11.1998 2.44019 9.14982 2.97018 7.90982 4.27018C6.69982 5.54018 6.41981 7.31018 7.11981 9.27018C7.25981 9.66018 7.05983 10.0901 6.66983 10.2301C6.57983 10.2501 6.49981 10.2702 6.39981 10.2602Z"
            fill="#008080"
          />
          <path
            d="M12 19.75C11.59 19.75 11.25 19.41 11.25 19V16C11.25 15.59 11.59 15.25 12 15.25C12.41 15.25 12.75 15.59 12.75 16V19C12.75 19.41 12.41 19.75 12 19.75Z"
            fill="#008080"
          />
          <path
            d="M12 23.75C10.48 23.75 9.25 22.52 9.25 21C9.25 19.48 10.48 18.25 12 18.25C13.52 18.25 14.75 19.48 14.75 21C14.75 22.52 13.52 23.75 12 23.75ZM12 19.75C11.31 19.75 10.75 20.31 10.75 21C10.75 21.69 11.31 22.25 12 22.25C12.69 22.25 13.25 21.69 13.25 21C13.25 20.31 12.69 19.75 12 19.75Z"
            fill="#008080"
          />
          <path
            d="M18 21.75H14C13.59 21.75 13.25 21.41 13.25 21C13.25 20.59 13.59 20.25 14 20.25H18C18.41 20.25 18.75 20.59 18.75 21C18.75 21.41 18.41 21.75 18 21.75Z"
            fill="#008080"
          />
          <path
            d="M10 21.75H6C5.59 21.75 5.25 21.41 5.25 21C5.25 20.59 5.59 20.25 6 20.25H10C10.41 20.25 10.75 20.59 10.75 21C10.75 21.41 10.41 21.75 10 21.75Z"
            fill="#008080"
          />
        </svg>
      ),
      title: "Cloud Services",
      description: `Harness the power of the cloud for enhanced scalability, security, and efficiency tailored for ${
        location === "us" ? "" : "Nigerian"
      } businesses`,
    },
    {
      image: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M16 16.75H8C7.59 16.75 7.25 16.41 7.25 16V8C7.25 7.59 7.59 7.25 8 7.25H16C16.41 7.25 16.75 7.59 16.75 8V16C16.75 16.41 16.41 16.75 16 16.75ZM8.75 15.25H15.25V8.75H8.75V15.25Z"
            fill="#008080"
          />
          <path
            d="M5 22.75C2.93 22.75 1.25 21.07 1.25 19C1.25 16.93 2.93 15.25 5 15.25H8C8.41 15.25 8.75 15.59 8.75 16V19C8.75 21.07 7.07 22.75 5 22.75ZM5 16.75C3.76 16.75 2.75 17.76 2.75 19C2.75 20.24 3.76 21.25 5 21.25C6.24 21.25 7.25 20.24 7.25 19V16.75H5Z"
            fill="#008080"
          />
          <path
            d="M8 8.75H5C2.93 8.75 1.25 7.07 1.25 5C1.25 2.93 2.93 1.25 5 1.25C7.07 1.25 8.75 2.93 8.75 5V8C8.75 8.41 8.41 8.75 8 8.75ZM5 2.75C3.76 2.75 2.75 3.76 2.75 5C2.75 6.24 3.76 7.25 5 7.25H7.25V5C7.25 3.76 6.24 2.75 5 2.75Z"
            fill="#008080"
          />
          <path
            d="M19 8.75H16C15.59 8.75 15.25 8.41 15.25 8V5C15.25 2.93 16.93 1.25 19 1.25C21.07 1.25 22.75 2.93 22.75 5C22.75 7.07 21.07 8.75 19 8.75ZM16.75 7.25H19C20.24 7.25 21.25 6.24 21.25 5C21.25 3.76 20.24 2.75 19 2.75C17.76 2.75 16.75 3.76 16.75 5V7.25Z"
            fill="#008080"
          />
          <path
            d="M19 22.75C16.93 22.75 15.25 21.07 15.25 19V16C15.25 15.59 15.59 15.25 16 15.25H19C21.07 15.25 22.75 16.93 22.75 19C22.75 21.07 21.07 22.75 19 22.75ZM16.75 16.75V19C16.75 20.24 17.76 21.25 19 21.25C20.24 21.25 21.25 20.24 21.25 19C21.25 17.76 20.24 16.75 19 16.75H16.75Z"
            fill="#008080"
          />
        </svg>
      ),
      title: "Technology Consulting",
      description:
        "Receive expert advice to navigate the evolving tech landscape, ensuring your business remains at the forefront of innovation",
    },
    {
      image: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20 14.75C18.48 14.75 17.25 13.52 17.25 12C17.25 10.48 18.48 9.25 20 9.25C21.52 9.25 22.75 10.48 22.75 12C22.75 13.52 21.52 14.75 20 14.75ZM20 10.75C19.31 10.75 18.75 11.31 18.75 12C18.75 12.69 19.31 13.25 20 13.25C20.69 13.25 21.25 12.69 21.25 12C21.25 11.31 20.69 10.75 20 10.75Z"
            fill="#008080"
          />
          <path
            d="M20 6.75C18.48 6.75 17.25 5.52 17.25 4C17.25 2.48 18.48 1.25 20 1.25C21.52 1.25 22.75 2.48 22.75 4C22.75 5.52 21.52 6.75 20 6.75ZM20 2.75C19.31 2.75 18.75 3.31 18.75 4C18.75 4.69 19.31 5.25 20 5.25C20.69 5.25 21.25 4.69 21.25 4C21.25 3.31 20.69 2.75 20 2.75Z"
            fill="#008080"
          />
          <path
            d="M20 22.75C18.48 22.75 17.25 21.52 17.25 20C17.25 18.48 18.48 17.25 20 17.25C21.52 17.25 22.75 18.48 22.75 20C22.75 21.52 21.52 22.75 20 22.75ZM20 18.75C19.31 18.75 18.75 19.31 18.75 20C18.75 20.69 19.31 21.25 20 21.25C20.69 21.25 21.25 20.69 21.25 20C21.25 19.31 20.69 18.75 20 18.75Z"
            fill="#008080"
          />
          <path
            d="M4 14.75C2.48 14.75 1.25 13.52 1.25 12C1.25 10.48 2.48 9.25 4 9.25C5.52 9.25 6.75 10.48 6.75 12C6.75 13.52 5.52 14.75 4 14.75ZM4 10.75C3.31 10.75 2.75 11.31 2.75 12C2.75 12.69 3.31 13.25 4 13.25C4.69 13.25 5.25 12.69 5.25 12C5.25 11.31 4.69 10.75 4 10.75Z"
            fill="#008080"
          />
          <path
            d="M18 12.75H6C5.59 12.75 5.25 12.41 5.25 12C5.25 11.59 5.59 11.25 6 11.25H18C18.41 11.25 18.75 11.59 18.75 12C18.75 12.41 18.41 12.75 18 12.75Z"
            fill="#008080"
          />
          <path
            d="M18 20.75H14C11.58 20.75 10.25 19.42 10.25 17V7C10.25 4.58 11.58 3.25 14 3.25H18C18.41 3.25 18.75 3.59 18.75 4C18.75 4.41 18.41 4.75 18 4.75H14C12.42 4.75 11.75 5.42 11.75 7V17C11.75 18.58 12.42 19.25 14 19.25H18C18.41 19.25 18.75 19.59 18.75 20C18.75 20.41 18.41 20.75 18 20.75Z"
            fill="#008080"
          />
        </svg>
      ),
      title: "DevOps",
      description:
        "Streamline your operations and software delivery with our integrated approach, optimizing both development and IT operations",
    },
    {
      image: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M7.99994 14.7495C7.80994 14.7495 7.61994 14.6795 7.46994 14.5295L5.46994 12.5295C5.17994 12.2395 5.17994 11.7595 5.46994 11.4695L7.46994 9.46945C7.75994 9.17945 8.23994 9.17945 8.52994 9.46945C8.81994 9.75945 8.81994 10.2395 8.52994 10.5295L7.05994 11.9995L8.52994 13.4695C8.81994 13.7595 8.81994 14.2395 8.52994 14.5295C8.37994 14.6795 8.18994 14.7495 7.99994 14.7495Z"
            fill="#008080"
          />
          <path
            d="M15.9999 14.7495C15.8099 14.7495 15.6199 14.6795 15.4699 14.5295C15.1799 14.2395 15.1799 13.7595 15.4699 13.4695L16.9399 11.9995L15.4699 10.5295C15.1799 10.2395 15.1799 9.75945 15.4699 9.46945C15.7599 9.17945 16.2399 9.17945 16.5299 9.46945L18.5299 11.4695C18.8199 11.7595 18.8199 12.2395 18.5299 12.5295L16.5299 14.5295C16.3799 14.6795 16.1899 14.7495 15.9999 14.7495Z"
            fill="#008080"
          />
          <path
            d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
            fill="#008080"
          />
          <path
            d="M11.0001 15.0806C10.9001 15.0806 10.8001 15.0606 10.7101 15.0206C10.3301 14.8606 10.1501 14.4206 10.3201 14.0306L12.3201 9.36053C12.4801 8.98053 12.9201 8.80051 13.3001 8.97051C13.6801 9.13051 13.8601 9.57056 13.6901 9.96056L11.6901 14.6305C11.5701 14.9105 11.2901 15.0806 11.0001 15.0806Z"
            fill="#008080"
          />
        </svg>
      ),
      title: "Software Development",
      description:
        "Craft bespoke software solutions, designed to meet unique business needs while prioritizing user experience and functionality",
    },
  ];
  return (
    <div className="px-5 md:px-10 lg:px-36 py-10 lg:pb-40 w-full flex items-center flex-wrap justify-between relative">
      <div className="w-full lg:w-1/2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="177"
          viewBox="0 0 6 177"
          fill="none"
          className="absolute -top-14 hidden lg:flex"
        >
          <path
            d="M3.00001 171.333C1.52725 171.333 0.333341 172.527 0.333341 174C0.333341 175.473 1.52725 176.667 3.00001 176.667C4.47277 176.667 5.66667 175.473 5.66667 174C5.66667 172.527 4.47277 171.333 3.00001 171.333ZM2.5 2.18557e-08L2.50001 174L3.50001 174L3.5 -2.18557e-08L2.5 2.18557e-08Z"
            fill="#454545"
          />
        </svg>
        <img
          src={active === 0 ? CS : active === 1 ? CC : active === 2 ? DCE : CT}
          className="w-full xl:w-[700px] lg:h-[525px]"
        />
      </div>
      <div className="w-full  lg:w-1/2 ">
        <div className="lg:mx-10">
          <Title mode={mode} text={"What We Do"} />
        </div>
        <div
          ref={scrollRef}
          className="w-full my-4 flex lg:flex-col w-full lg:flex  h-[235px] lg:h-full overflow-x-scroll lg:overflow-visible"
          onScroll={handleScroll}
          style={{ scrollbarColor: "white" }}
        >
          {Data.map((card, index) => (
            <div
              className="w-full h-[185px] lg:h-full mx-3 lg:mx-0 my-5"
              onClick={() => setActive(index)}
            >
              <WhatWeDoCard
                mode={mode}
                card={card}
                index={index}
                active={active}
                location={location}
                // onClick={(prop) => setActive(prop)}
              />
            </div>
          ))}
        </div>
        <div className="w-1/3 md:w-1/5 mx-auto flex lg:hidden items-center mx-10">
          {Data.map((card, index) => (
            <div
              key={index}
              className={
                currentItem === index
                  ? `w-[40px] ${
                      mode === "light" ? "bg-[#066769]" : "bg-[#15ECE2]"
                    } rounded-[12px] p-0.5 mx-1`
                  : `w-[12px]  ${
                      mode === "light" ? "bg-[#15ECE2]" : "bg-[#066769]"
                    }  rounded-[12px] p-0.5 mx-1`
              }
            ></div>
          ))}
        </div>
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2"
        height="174"
        viewBox="0 0 2 174"
        fill="none"
        className="absolute bottom-20 hidden lg:flex"
      >
        <path d="M1 0L1.00001 174" stroke="#454545" />
      </svg>
    </div>
  );
};
export default WhatWeDo;
