/** @format */

import React, { useEffect } from "react";
import "../styles/Light.css";
import "../styles/Dark.css";
import "../styles/Shared.css";
import "../App.css";
import "../styles/fonts.css";
import Header from "../components/Header";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../components/Footer";
// import { GetUserLocation } from "../redux/actions/user";
import Cookies from "../components/Cookies";
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";

  const Main = ({ App, showSearch, data, location }) => {
    const Mode = useSelector((state) => state.user.mode);
    // const location = useSelector((state) => state.user.location);

    const dispatch = useDispatch();

    // useEffect(() => {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(success, error);
    //   } else {
    //     console.log("Geolocation not supported");
    //   }

    //   function success(position) {
    //     const latitude = position.coords.latitude;
    //     const longitude = position.coords.longitude;
    //     CheckRunLocator(dispatch, longitude, latitude, Mode);
    //   }

    //   function error() {
    //     console.log("Unable to retrieve your location");
    //   }
    // }, [Mode]);

    // const CheckRunLocator = (dispatch, longitude, latitude, mode) => {
    //   if (!location) {
    //     GetUserLocation(dispatch, longitude, latitude);
    //   } else {
    //     console.log("allow that man");
    //   }
    // };
    return (
      <div className={`w-full background ${Mode}`}>
        <Header mode={Mode} location={location} />
        <Cookies mode={Mode} />
        <div className="mt-20 lg:mt-0 pt-14">{App(Mode, location)}</div>
        <Footer location={location} showSearch={showSearch} data={data} />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    );
  };
export default Main;
