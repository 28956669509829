/** @format */

import React, { useState, useEffect } from "react";
import Title from "./Title";
import Projects from "./Projects";
import { urlFor, client } from "../client";

const LandingPageProject = ({ mode }) => {
	const [projects, setProjects] = useState([]);

	useEffect(() => {
		const query = '*[_type == "portfolio"] | order(date desc)';

		client.fetch(query).then((data) => {
			setProjects(data);
		});
	}, []);

	return (
    <div className="px-5 md:px-10 lg:px-36 lg:py-10 w-full relative">
      <Title text={"Projects"} mode={mode} />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="177"
        viewBox="0 0 6 177"
        fill="none"
        className="absolute -top-20 right-44 hidden lg:flex"
      >
        <path
          d="M3.00001 171.333C1.52725 171.333 0.333341 172.527 0.333341 174C0.333341 175.473 1.52725 176.667 3.00001 176.667C4.47277 176.667 5.66667 175.473 5.66667 174C5.66667 172.527 4.47277 171.333 3.00001 171.333ZM2.5 2.18557e-08L2.50001 174L3.50001 174L3.5 -2.18557e-08L2.5 2.18557e-08Z"
          fill="#D1D1D1"
        />
      </svg>
      <div className="lg:my-5">
        <div className="flex items-center flex-wrap lg:my-5">
          <div className="mt-10 lg:mt-60">
            {projects.slice(0, 2).map((project, index) => (
              <Projects
                image={urlFor(project.featuredImage)}
                title={project.title}
                description={project.overview}
                mode={mode}
                key={index}
                project={project}
                projects={projects}
                url={`/project/${project._id}`}
              />
            ))}
          </div>
          <div className="mt-5  ml-5">
            {projects.slice(2, 4).map((project, index) => (
              <Projects
                image={urlFor(project.featuredImage)}
                title={project.title}
                description={project.overview}
                mode={mode}
                key={index}
                project={project}
                projects={projects}
                url={`/project/${project._id}`}
              />
            ))}
          </div>
        </div>
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="66"
        viewBox="0 0 6 66"
        fill="none"
        className="absolute right-44 hidden lg:flex"
      >
        <path
          d="M3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333ZM3 60.3333C1.52724 60.3333 0.333336 61.5272 0.333336 63C0.333336 64.4728 1.52724 65.6667 3 65.6667C4.47276 65.6667 5.66667 64.4728 5.66667 63C5.66667 61.5272 4.47276 60.3333 3 60.3333ZM2.5 3L2.5 63L3.5 63L3.5 3L2.5 3Z"
          fill="#D1D1D1"
        />
      </svg>
    </div>
  );
};
export default LandingPageProject;
