import React from "react";
import ProjectButton from "./ProjectButton";
import { Link } from "react-router-dom";

const BlogCard = ({ image, date, title, desc, featured, mode, url }) => {
	return (
    <div
      className={`blog-card ${mode} p-5 w-full h-full lg:w-[532px] relative lg:h-full md:w-[700px] md:h-[313px] flex lg:flex-col  items-center rounded-[12px]`}
    >
      <div
        className={`blog-card-tag ${mode} md:hidden w-3/5 md:flex text-[8px] md:text-[14px] p-0.5  md:p-2 rounded-full absolute top-1 md:top-3 left-2 `}
      >
        Digital Transformation, Machine Learning
      </div>
      <div className="w-full relative">
        <img
          src={image}
          alt=""
          className="lg:w-[500px] lg:h-[384px] md:w-[324px] md:h-[281px] w-[167px] h-[142px] rounded-[12px] mt-3 md:mt-0"
        />
        <div
          className={`blog-card-tag ${mode} hidden md:w-3/5  text-center md:flex text-[8px] md:text-[12px] lg:text-[14px] p-1 md:p-2 rounded-full absolute  md:top-3 left-1 lg:left-3 bg-white`}
        >
          Digital Transformation, Machine Learning
        </div>
        <div className="md:hidden mx-auto mt-2">
          <Link to={url} target="_blank">
            <ProjectButton text="Continue Reading" fullWidth mode={mode} />
          </Link>
        </div>
      </div>
      <div className={`w-full mx-3 mb-10 md:mb-0`}>
        <p
          className={`blog-card-header-text ${mode} text-[#6D6D6D] py-1 lg:py-3 text-[8px] md:text-[12px] lg:text-[16px]`}
        >
          {date}
        </p>
        <h1
          className={` md:hidden blog-card-header-text ${mode} font-bold  text-[14px] lg:text-[36px]`}
        >
          {title.slice(0, 25)}
          {title.length > 25 ? "..." : ""}
        </h1>
        <h1
          className={`hidden md:flex blog-card-header-text ${mode} font-bold  text-[20px] lg:text-[36px]`}
        >
          {title}
        </h1>
        <p
          className={`py-2  md:hidden blog-card-header-text ${mode}  lg:py-4 text-[10px] md:text-[14px] lg:text-[16px]`}
        >
          {desc.slice(0, 100)}
          {desc.length > 100 ? "..." : ""}
        </p>

        <p
          className={`py-2 hidden md:flex blog-card-header-text ${mode}  lg:py-4 text-[10px] md:text-[14px] lg:text-[16px]`}
        >
          {desc.slice(0, 180)}
          {desc.length > 180 ? "..." : ""}
        </p>
        <div className="hidden w-full md:flex flex-col">
          <Link to={url} target="_blank">
            <ProjectButton text="Continue Reading" fullWidth mode={mode} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
