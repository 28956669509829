/** @format */

import React from "react";

const WhatWeDoCard = ({ mode, card, active, index }) => {
  console.log(active);
  const styles = {
    dark: {
      container: {
        background: active === index ? "#15ECE2" : "#262626",
        border: "1px solid #008080",
      },
      title: {
        color: active === index ? "#003235" : "#00A8A5",
      },
      image: {
        background: "#003235",
      },
      description: {
        color: active === index ? "#003235" : "#D1D1D1",
      },
    },

    light: {
      container: {
        background: active === index ? "#EEFFFC" : "#FFF",
        border: `1px solid  ${active === index ? "#008080" : "#E7E7E7"}`,
      },
      image: {
        background: active === index ? "#C5FFFA" : "#EEFFFC",
      },
      description: {
        color: "#4F4F4F",
      },
    },
  };
  return (
    <div
      className="mr-44 lg:mx-10 w-full h-full lg:w-[532px]  p-5 py-5 rounded-[12px] bg-[#262626]  cursor-pointer"
      style={mode === "dark" ? styles.dark.container : styles.light.container}
      key={index}
      // onClick={() => onClick(index)}
    >
      <div className="flex items-center">
        <div
          className="p-3 rounded-[12px] bg-[black]"
          style={mode === "dark" ? styles.dark.image : styles.light.image}
        >
          {card.image}
        </div>
        <h1
          className={`pri-teal ${mode} font-bold text-[18px] mx-3`}
          style={mode === "dark" ? styles.dark.title : styles.light.title}
        >
          {card.title}
        </h1>
      </div>
      <p
        className={`text-[16px] py-3  w-full`}
        style={
          mode === "dark" ? styles.dark.description : styles.light.description
        }
      >
        {card.description}
      </p>
    </div>
  );
};

export default WhatWeDoCard;
