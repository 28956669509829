/** @format */

import React, { useState } from "react";
import Main from "../containers/Main";
import Banner from "../components/Banner";
import Map from "../components/Map";
import { motion, useIsPresent } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  const App = (mode) => {
    const [contactDetails, setContactDetails] = useState({
      email: "",
      name: "",
      message: "",
    });
    const [loadText, setLoadText] = useState("Send Message");

    const handleSubmit = async (e) => {
      e.preventDefault();

      if (
        contactDetails.email === "" ||
        contactDetails.name === "" ||
        contactDetails.message === ""
      ) {
        return toast.error(`Please Fill the Empty Fields `, {
          position: "top-right",
          autoClose: 5000,
          theme: mode === "light" ? "light" : "dark",
        });
      }
      setLoadText("...");

      // Use Formspree's endpoint as the form action
      const formspreeEndpoint = "https://formspree.io/f/mrgwwpnl";

      try {
        // Make a POST request to Formspree
        await fetch(formspreeEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(contactDetails),
        });

        // Optionally, you can handle success, reset the form, or show a success message
        setContactDetails({
          email: "",
          name: "",
          message: "",
        });
        setLoadText("Send Message");

        toast.success("Feedback submitted successfully!", {
          position: "top-right",
          autoClose: 5000,
          theme: mode === "light" ? "light" : "dark",
        });
      } catch (error) {
        // Handle errors, such as network issues or server errors
        toast.error(`Error submitting feedback:${error}`, {
          position: "top-right",
          autoClose: 5000,
          theme: mode === "light" ? "light" : "dark",
        });
      }
    };
    return (
      <div>
        <motion.div
          initial={{ opacity: 0, y: "100%" }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: "100%" }}
          transition={{ duration: 0.5 }}
        >
          <Banner title="Contact Us" mode={mode} />

          <div className="w-full flex flex-wrap items-center ">
            <div className="w-full lg:w-1/2 py-5 lg:py-0 px-5 md:px-10 lg:px-20 ">
              <h1
                className={`text-[21px] md:text-[61px] font-bold mb-7 `}
                style={{ color: `${mode === "light" ? "#333333" : "#FFF"}` }}
              >
                Get in Touch
              </h1>
              <form onSubmit={handleSubmit}>
                {" "}
                <input
                  onChange={(event) =>
                    setContactDetails((prevState) => ({
                      ...prevState,
                      name: event.target.value,
                    }))
                  }
                  value={contactDetails?.name}
                  placeholder="Full Name"
                  className="outline-none bg-[white] rounded-[12px] py-4 px-4 mt-5 w-full text-[#6D6D6D]"
                  style={{
                    border: `1px solid ${
                      mode === "light" ? "#E7E7E7" : "#15ECE2"
                    }`,
                    background: mode === "light" ? "#FFF" : "#333",
                  }}
                />
                <input
                  onChange={(event) =>
                    setContactDetails((prevState) => ({
                      ...prevState,
                      email: event.target.value,
                    }))
                  }
                  value={contactDetails?.email}
                  placeholder="Email Address"
                  className="outline-none rounded-[12px] py-4 px-4 mt-5 w-full text-[#6D6D6D]"
                  style={{
                    border: `1px solid ${
                      mode === "light" ? "#E7E7E7" : "#15ECE2"
                    }`,
                    background: mode === "light" ? "#FFF" : "#333",
                  }}
                />
                <textarea
                  onChange={(event) =>
                    setContactDetails((prevState) => ({
                      ...prevState,
                      message: event.target.value,
                    }))
                  }
                  value={contactDetails?.message}
                  placeholder="Enter Your Message Here..."
                  className="outline-none bg-[white] rounded-[12px] h-[244px] resize-none py-4 px-4 mt-5 w-full text-[#6D6D6D]"
                  style={{
                    border: `1px solid ${
                      mode === "light" ? "#E7E7E7" : "#15ECE2"
                    }`,
                    background: mode === "light" ? "#FFF" : "#333",
                  }}
                ></textarea>
                <div
                  className="flex w-full lg:w-1/3 my-5 font-semibold text-[16px]  px-auto text-center mx-auto items-center py-3 sm:py-4 px-3 sm:px-5 rounded-[8px] cursor-pointer "
                  style={{
                    color: `${mode === "light" ? "#FFFFFF" : "#333333"}`,
                    background: `${mode === "light" ? "#008080" : "#15ECE2"}`,
                  }}
                >
                  <button className="text-center w-full" type="submit">
                    {loadText}
                  </button>
                </div>
              </form>
            </div>
            <div className="w-full lg:w-1/2">
              <Map location="ng" />
            </div>
          </div>
        </motion.div>
        <ToastContainer />
      </div>
    );
  };
  return (
    <div>
      <Main App={App} />
    </div>
  );
};

export default ContactUs;
