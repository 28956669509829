/** @format */

import React from "react";
import Main from "../containers/Main";
import { Link } from "react-router-dom";

const ThankYou = () => {
  const App = () => (
    <div className="my-40 text-center">
      <h1 className=" text-3xl font-bold">
        YOUR APPLICATION HAS BEEN SUBMITTED!
      </h1>

      <h3 className="text-xl my-5">
        Our team will get in contact with you soon
      </h3>
      <h5>
        View more jobs{" "}
        <span className="text-[blue]">
          <Link to="/careers">here</Link>
        </span>
        .
      </h5>
    </div>
  );
  return (
    <div>
      <Main App={App} />
    </div>
  );
};

export default ThankYou;
